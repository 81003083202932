import React, { Component } from "react";
import logoImg from '../../assets/braun/logo.jpg'
const Sheet1 = [
    {
        code: "北区",
        city: "",
        name: "",
        address: "",
        contact: "",
    },
    {
        code: 200085,
        city: "上海",
        name: "上海开呈家电维修服务有限公司",
        address: "上海市黄浦区瑞金南路345弄1号楼6B01室",
        contact: "021-63566755/56329466",
    },
    {
        code: 201100,
        city: "上海",
        name: "上海千捷家电有限公司",
        address: "上海市闵行区雅致路31弄1号105室",
        contact: "021-64122533",
    },
    {
        code: 100078,
        city: "北京",
        name: "北京盛侨家用电器维修服务中心",
        address: "北京市丰台区方庄芳群园2区6号楼金芳公寓408室",
        contact: "010-67698025/67630617",
    },
    {
        code: 300022,
        city: "天津",
        name: "天津市大东电器维修有限公司",
        address: "天津市和平区河北路50号信德大厦908-2",
        contact: "022-27120168/27120170",
    },
    {
        code: 110011,
        city: "沈阳",
        name: "沈阳市沈河区德朗电器维修中心",
        address: "沈阳市沈河区承德路36-1",
        contact: "024-24870309",
    },
    {
        code: 150020,
        city: "哈尔滨",
        name: "哈尔滨宪成晖家用电器有限公司",
        address: "哈尔滨市道外区南勋街18号",
        contact: "0451-88940125",
    },
    {
        code: 116021,
        city: "大连",
        name: "大连中服电子技术服务中心",
        address: "辽宁省大连市沙河口区西南路800-2号",
        contact: "0411-86841988",
    },
    {
        code: 130021,
        city: "长春",
        name: "长春圣炎电子产品有限公司",
        address: "长春市朝阳区惠民路北胡同49号",
        contact: "0431-85676646",
    },
    {
        code: 266071,
        city: "青岛",
        name: "市南区洪双嘉电子商行",
        address: "青岛市南区银川西路3号政泰商务楼202室",
        contact: "0532-85977910",
    },
    {
        code: 50000,
        city: "石家庄",
        name: "河北安森钟表维修有限公司",
        address: "石家庄市中山西路108号华润万象城C座2518室",
        contact: "0311-89891170",
    },
    {
        code: 310016,
        city: "杭州",
        name: "杭州中侨家电维修服务有限公司",
        address: "杭州市采荷路10号",
        contact: "0571-86047438",
    },
    {
        code: 230001,
        city: "合肥",
        name: "合肥市包河区国升电器维修安装服务部",
        address:
            "安徽省合肥市经开区青龙潭路与紫云路交口长安萨尔斯堡商铺9栋107",
        contact: "0551-63682143/68992143",
    },
    {
        code: 450000,
        city: "郑州",
        name: "郑州百川电器技术有限公司",
        address: "郑州市北下街79号",
        contact: "0371-66964036",
    },
    {
        code: 210003,
        city: "南京",
        name: "南京博伊华电子电器经营部",
        address: "南京市中山北路223号建达大厦409室",
        contact: "025-83420092",
    },
    {
        code: 214031,
        city: "无锡",
        name: "无锡市松信环境科技有限公司",
        address: "无锡市五爱北路27号",
        contact: "0510-82729805",
    },
    {
        code: 400020,
        city: "重庆",
        name: "江北区中侨家电维修部",
        address: "重庆市江北区建新南路18号城市领地A栋附2楼77号门市",
        contact: "15696126296;023-63527117",
    },
    {
        code: "南区",
        city: "",
        name: "",
        address: "",
        contact: "",
    },
    {
        code: 201112,
        city: "上海",
        name: "上海馨美电器有限公司",
        address: "上海市闵行区浦锦路2049弄(万科VMO)39号301室",
        contact: "021-54475808;021-64606449;021-20916662",
    },
    {
        code: 213001,
        city: "常州",
        name: "常州市金博家电经营部",
        address: "常州市天宁区迎宾路江南佳园4-10",
        contact: "0519-88836628",
    },
    {
        code: 226001,
        city: "南通",
        name: "崇川区明瑞电器维修服务中心",
        address: "南通市南川园通明花苑6幢106店面",
        contact: "0513-96889688/85105088",
    },
    {
        code: 315040,
        city: "宁波",
        name: "宁波市江东明楼甬城家电维修部",
        address: "宁波市百丈东路892号万金大厦1306室",
        contact: "0574-87774444/87771344",
    },
    {
        code: 325029,
        city: "温州",
        name: "温州市声像技术服务公司",
        address: "温州市鹿城区宽带路14号-8",
        contact: "0577-88850706",
    },
    {
        code: 510600,
        city: "广州",
        name: "广州市博健电器有限公司",
        address: "广州市越秀区五羊新城春风路E12栋首层博朗",
        contact: "020-87386165/87386166",
    },
    {
        code: 518028,
        city: "深圳",
        name: "深圳市顺电连锁股份有限公司",
        address: "广东省深圳市罗湖区深南东路2102号振华大厦2楼维修部",
        contact: "0755-83286255或83286261",
    },
    {
        code: 650051,
        city: "昆明",
        name: "昆明松凯科技有限公司",
        address: "昆明市环城北路233号城市之光A1301",
        contact: "0871-63645557",
    },
    {
        code: 430015,
        city: "武汉",
        name: "武汉市江岸区兆利达家电维修中心",
        address: "武汉市江汉区唐家墩路203号附26号门面",
        contact: "027-85730517/85790611",
    },
    {
        code: 610017,
        city: "成都",
        name: "成都翱驰泰达电子产品有限公司",
        address: "四川省成都市青羊区大安东路57号附1-3号",
        contact: "028-86944135",
    },
    {
        code: 350001,
        city: "福州",
        name: "福州福春电器维修服务有限公司",
        address: "福州市晋安区岳峰镇桃花山巷94号",
        contact: "0591-87582122",
    },
    {
        code: 361003,
        city: "厦门",
        name: "厦门市思明区新鹭声电子技术服务部",
        address: "厦门后埭溪路248号之6、7、8号",
        contact: "0592-5170118",
    },
    {
        code: 330003,
        city: "南昌",
        name: "南昌市西湖区海洋电器服务部",
        address: "南昌市永叔路6号",
        contact: "0791-86215790/86263831",
    },
    {
        code: 530001,
        city: "南宁",
        name: "南宁市南方家电维修中心",
        address: "广西南宁市唐山路29号首层",
        contact: "0771-3118895/3907787",
    },
];
class ServiceAddress extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        document.title = '博朗,博朗剃须刀-博朗最新全国销售门店地址'
    }

    render() {
        let {  } = this.state
        return (
            <div className="homePages">
                <h2>
                    <img className="logo" src={logoImg} alt=""/>
                    最新售后服务部地址
                </h2>
                <div className="pure-table">
                    <div className="titTable">
                        <div className='tab1s'>邮编</div>
                        <div className='tab2s'>城市</div>
                        <div className='tab3s'>公司名称</div>
                        <div className='tab4'>地址</div>
                        <div className='tab5s'>联系电话</div>
                    </div>
                    {
                        Sheet1.map((item,index) => {
                            return (
                                <div className="titTables" key={index}>
                                    <div className='tab1s'>{item.code}</div>
                                    <div className='tab2s'>{item.city}</div>
                                    <div className='tab3s'>{item.name}</div>
                                    <div className='tab4'>{item.address}</div>
                                    <div className='tab5s'>{item.contact}</div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="footers">
                    <img className="footer_logos" src={logoImg} alt=""/>
                        <div className="footer_descs">
                            <div>广州宝洁有限公司</div>
                            <div>地址：广州市经济技术开发区滨河路一号</div>
                            <div>上述售后服务部地址确定于2021年11月，可能有不定期更新。</div>
                            <div>如有疑问，请咨询客户服务热线：4008333010 / 微信：pgcrcare</div>
                        </div>
                </div>
            </div>
        )
    }
}

export default ServiceAddress;
