import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RoutePath from "../config/RoutePath";
import Index from '../views/index/Index';
import Safeguard from '../views/safeguard/Safeguard';
import Whisper from '../views/whisper/Whisper';
import Rejoice from '../views/rejoice/Rejoice';
import Vs from '../views/vs/Vs';
import Pantene from '../views/pantene/Pantene';
import HeadShoulders from '../views/headShoulders/HeadShoulders';
import Shenghuojia from '../views/shenghuojia/Shenghuojia';
import Downy from '../views/downy/Downy';
import Crest from '../views/crest/Crest';
import Oralb from '../views/oralb/Oralb';
import Pampers from '../views/pampers/Pampers';
import Gillette from '../views/gillette/Gillette';
import Braun from '../views/braun/Braun';
import ServiceAddress from '../views/braun/ServiceAddress';
import StoreAddress from '../views/braun/StoreAddress';
import Olay from '../views/olay/Olay';
import Gillettevector2 from '../views/gillettevector2/Gillettevector2';
import Gillettevector3 from '../views/gillettevector3/Gillettevector3';
import Clause from '../views/clause/Clause';
import Privacy from '../views/privacy/Privacy';
import Dmt_privacy from '../views/dmt_privacy/Dmt_privacy';

function Root () {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={RoutePath.Index} element={<Index />}></Route>
                <Route path={RoutePath.Safeguard} element={<Safeguard />}></Route>
                <Route path={RoutePath.Whisper} element={<Whisper />}></Route>
                <Route path={RoutePath.Rejoice} element={<Rejoice />}></Route>
                <Route path={RoutePath.Vs} element={<Vs />}></Route>
                <Route path={RoutePath.Pantene} element={<Pantene />}></Route>
                <Route path={RoutePath.HeadShoulders} element={<HeadShoulders />}></Route>
                <Route path={RoutePath.Shenghuojia} element={<Shenghuojia />}></Route>
                <Route path={RoutePath.Downy} element={<Downy />}></Route>
                <Route path={RoutePath.Crest} element={<Crest />}></Route>
                <Route path={RoutePath.Oralb} element={<Oralb />}></Route>
                <Route path={RoutePath.Pampers} element={<Pampers />}></Route>
                <Route path={RoutePath.Gillette} element={<Gillette />}></Route>
                <Route path={RoutePath.Braun} element={<Braun />}></Route>
                <Route path={RoutePath.ServiceAddress} element={<ServiceAddress />}></Route>
                <Route path={RoutePath.StoreAddress} element={<StoreAddress />}></Route>
                <Route path={RoutePath.Olay} element={<Olay />}></Route>
                <Route path={RoutePath.Gillettevector2} element={<Gillettevector2 />}></Route>
                <Route path={RoutePath.Gillettevector3} element={<Gillettevector3 />}></Route>
                <Route path={RoutePath.Clause} element={<Clause />}></Route>
                <Route path={RoutePath.Privacy} element={<Privacy />}></Route>
                <Route path={RoutePath.Dmt_privacy} element={<Dmt_privacy />}></Route>
            </Routes>
        </BrowserRouter>
    )
};

export default Root;
