import React, {Component} from "react";
import Safeguard from '../safeguard/Safeguard';
import Whisper from '../whisper/Whisper';
import Rejoice from '../rejoice/Rejoice';
import Vs from '../vs/Vs';
import Pantene from '../pantene/Pantene';
import HeadShoulders from '../headShoulders/HeadShoulders';
import Shenghuojia from '../shenghuojia/Shenghuojia';
import Downy from '../downy/Downy';
import Crest from '../crest/Crest';
import Oralb from '../oralb/Oralb';
import Pampers from '../pampers/Pampers';
import Gillette from '../gillette/Gillette';
import Braun from '../braun/Braun';
import Olay from '../olay/Olay';
import Gillettevector2 from '../gillettevector2/Gillettevector2';
import Gillettevector3 from '../gillettevector3/Gillettevector3';
import Dmt_privacy from "../dmt_privacy/Dmt_privacy";
import Privacy from "../privacy/Privacy";
import Clause from "../clause/Clause";

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    judgePage() {
        let url = window.location.href
        console.log('url', url)
        let page = null

        if (url.indexOf('safeguard-qa.shenghuojia.com') !== -1) {
            page = <Braun></Braun>
        } else if (url.indexOf('c/privacy/') !== -1) {
            page = <Privacy></Privacy>
        } else if (url.indexOf('rejoice-qa.shenghuojia.com') !== -1) {
            page = <Dmt_privacy></Dmt_privacy>
        } else if (url.indexOf('vs-qa.shenghuojia.com/privacy') !== -1) {
            page = <Braun></Braun>
        } else if (url.indexOf('panteneweb-qa.shenghuojia.com') !== -1) {
            page = <Pantene></Pantene>
        } else if (url.indexOf('head-shoulders') !== -1) { // TODO
            page = <HeadShoulders></HeadShoulders>
        } else if (url.indexOf('downy') !== -1) { // TODO
            page = <Downy></Downy>
        } else if (url.indexOf('crest') !== -1) { // TODO
            page = <Crest></Crest>
        } else if (url.indexOf('oralb') !== -1) { // TODO
            page = <Oralb></Oralb>
        } else if (url.indexOf('www.pampers.com.cn') !== -1) { // TODO 缺少qa域名
            page = <Pampers></Pampers>
        } else if (url.indexOf('www.braun.com.cn') !== -1) { // TODO 缺少qa域名
            page = <Braun></Braun>
        } else if (url.indexOf('olay') !== -1) { // TODO
            page = <Olay></Olay>
        } else if (url.indexOf('gillettevector2') !== -1) { // TODO
            page = <Gillettevector2></Gillettevector2>
        } else if (url.indexOf('gillettevector3') !== -1) { // TODO
            page = <Gillettevector3></Gillettevector3>
        } else if (url.indexOf('gillette') !== -1) { // TODO
            page = <Gillette></Gillette>
        } else if (url.indexOf('shenghuojia') !== -1) { // TODO
            page = <Shenghuojia></Shenghuojia>
        }else if (url.indexOf('www.safeguard.com.cn') !== -1) {
            page = <Safeguard></Safeguard>
        }else if (url.indexOf('www.whisper.com.cn') !== -1) {
            page = <Whisper></Whisper>
        }else if (url.indexOf('www.rejoice.com.cn') !== -1) {
            page = <Rejoice></Rejoice>
        }else if (url.indexOf('www.vs.com.cn') !== -1) {
            page = <Vs></Vs>
        }else if (url.indexOf('www.pantene.com.cn') !== -1) {
            page = <Pantene></Pantene>
        }else if (url.indexOf('www.head-shoulders.com.cn') !== -1) {
            page = <HeadShoulders></HeadShoulders>
        }else if (url.indexOf('www.shenghuojia.com') !== -1) {
            page = <Shenghuojia></Shenghuojia>
        }else if (url.indexOf('downy.shenghuojia.com') !== -1) {
            page = <Downy></Downy>
        }else if (url.indexOf('www.crest.com.cn') !== -1) {
            page = <Crest></Crest>
        }else if (url.indexOf('www.oralb.com.cn') !== -1) {
            page = <Oralb></Oralb>
        }else if (url.indexOf('www.pampers.com.cn') !== -1) {
            page = <Pampers></Pampers>
        }else if (url.indexOf('www.gillette.com.cn') !== -1) {
            page = <Gillette></Gillette>
        }else if (url.indexOf('www.braun.com.cn') !== -1) {
            page = <Braun></Braun>
        }else if (url.indexOf('www.olay.com.cn') !== -1) {
            page = <Olay></Olay>
        }else if (url.indexOf('www.gillettevector2.com') !== -1) {
            page = <Gillettevector2></Gillettevector2>
        }else if (url.indexOf('www.gillettevector3.com') !== -1) {
            page = <Gillettevector3></Gillettevector3>
        }else if (url.indexOf('www.oralb.com.cn/dmt_privacy') !== -1) {
            page = <Dmt_privacy></Dmt_privacy>
        }else if (url.indexOf('www.oralb.com.cn/privacy') !== -1) {
            page = <Privacy></Privacy>
        }else if (url.indexOf('www.oralb.com.cn/clause') !== -1) {
            page = <Clause></Clause>
        }else if (url.indexOf('www.oralb.com.cn/dmt_privacy/') !== -1) {
            page = <Dmt_privacy></Dmt_privacy>
        }else if (url.indexOf('www.oralb.com.cn/privacy/') !== -1) {
            page = <Privacy></Privacy>
        }else if (url.indexOf('www.oralb.com.cn/clause/') !== -1) {
            page = <Clause></Clause>
        } else {
            page = <Shenghuojia></Shenghuojia>
        }
        return page
    }

    render() {
        return (
            <div>
                {
                    this.judgePage()
                }
            </div>
        )
    }
}

export default Home;
