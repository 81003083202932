import React, { Component } from "react";

class Clause extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        document.title = 'Oral-B 移动应用程序'

    }

    render() {
        let {} = this.state
        return (
            <div id="clause">
                <h2>Oral-B 移动应用程序
                </h2>
                <h2>使用条款
                </h2>
                <h2>最后修订：2021 年 4月
                </h2>
                <p>
                    欢迎您下载、浏览、参与和/或使用Oral-B 移动应用程序。在您浏览、参与和/或使用Oral-B 移动应用程序时，请仔细阅读、理解及遵守本使用条款的相关内容。请您注意，本使用条款包含非常重要的信息，说明了您在浏览、注册、参与和/或使用 Oral-B 移动应用程序后可能适用的权利和义务，以及相关限制和排除规定。
                </p>
                <p>
                    对于 Procter & Gamble Productions, Inc.（以下称为"宝洁公司"或"我们"）提供的 Oral-B 移动应用程序（以下称为"应用程序"），所有访问者（包括但不限于个人和实体代表，统称为"您"）的使用都受到本协议（定义见下）的约束。
                </p>
                <p>
                    本使用条款以及通过本应用程序所罗列或传达的任何其他披露信息、更新内容、规则、发布信息、公告或其他条款（统称为"协议"）构成您与宝洁公司之间具有约束力的法律协议。下载、浏览、使用或注册本应用程序的任何部分，即表示您同意遵循本协议所列的所有条款。如果您不同意这些条款，请勿下载、浏览、注册、参与或使用本应用程序。
                </p>
                <p>
                    关于本应用程序：本应用程序专门针对欧乐B 电动牙刷设计，可与其配套使用。本应用程序将帮助您完善刷牙过程，维护健康牙龈，自信地展现迷人笑容。
                </p>
                <p>
                    本应用程序及其连接设备（欧乐B 电动牙刷）并非医疗设备，而是作为健康生活方式的一部分，帮助您培养正确的刷牙习惯。有关正确的刷牙技巧或与医疗相关的信息或资讯，请联系您的牙医或专业人士。
                </p>
                <p>
                    蓝牙：要连接欧乐B电动牙刷，需要使用支持蓝牙 4.0 技术的设备。
                </p>
                <p>
                    支持的手机：请点击下面的链接 <a href="https://app.oralb.com/" target="_blank">https://app.oralb.com/</a>，了解更多关于所支持的手机设备的信息。
                </p>
                <p>
                    隐私：信任是我们企业使命的基石，也是取得业务成功的关键所在。宝洁公司致力于保持您对我们的信任，对收集的有关您（我们的消费者）的个人信息进行保护。在本应用程序向您收集的任何个人身份识别信息，都受宝洁公司Oral-B 应用程序隐私政策所规定的准则约束，详见本APP“更多”-“法律”-“隐私政策”。宝洁公司的全球消费者隐私政策现已纳入本协议，并构成本协议的一部分。
                </p>
                <p>
                    参与资格：您可完全自主地决定是否参与。您陈述并保证，您提交的所有信息都是真实准确完整的。您同意会维持此类信息的准确性和完整性。您进一步陈述并保证，您已年满十八 (18)周岁，且具有资格和能力签订有约束力的合约、合同或协议。您承诺您对本应用程序的使用不得违反任何适用的法律法规。
                </p>
                <p>
                    移动设备：使用移动设备可能需要向无线服务运营商支付每月费用。您应向参与活动的运营商订阅移动通信服务，或者可访问本应用程序支持的移动通信网络。您应具有连接至本应用程序所需的所有设备和软件，包括但不限于工作正常且由我们自行酌情决定认为适合连接本应用程序使用的移动手持式或其他移动设备。您应承担可能因您访问、下载和使用本应用程序而产生的任何移动数据费用和其他第三方收费，例如运营商可能收取的费用。
                </p>
                <p>
                    您的运营商可能收取标准短信费、数据费及其他费用。这些费用会出现在您的手机账单上，或从您预付的费用中扣除。您的运营商可能会禁用或限制使用某些功能，这些功能可能不适用于您的运营商提供的服务或您的移动设备。如您对此有疑问，请联系您的运营商。在适用的情况下，对于这些功能，运营商将您向提供如何选择不参加某些移动设备短信收发的说明。这些说明一般会要求您输入相关简码的关键词（例如，"停止"、"取消"、"结束"、"取消订阅"、"退出"等）。
                </p>
                <p>
                    本应用程序可通过许多 iOS 和 Android 移动设备上相应的应用程序门户进行下载。因为交付情况取决于您的网络运营商的传输服务有效性，宝洁公司不对延迟接收任何提交内容承担责任。
                </p>
                <p>
                    宝洁公司不对如下内容进行保证：本应用程序所含的功能一直不间断运行或不会出现错误；或者缺陷问题将即时得到更正；或者本应用程序不包含病毒或其他有害部分。对于使用本应用程序或欧乐B电动牙刷中材料或此类使用的结果，宝洁公司不提供任何正确性、准确性、可靠性等方面的陈述或保证。本应用程序可能会因多种原因不时发生中断，例如因为您的无线服务运营商不提供相应的移动网络覆盖，以及宝洁公司或该无线服务运营商经历或执行中断和定期维护。
                </p>
                <p>
                    您了解并同意，使用本应用程序及与之配套的欧乐B电动牙刷是出于您的自行决定，并由您自行承担相关风险。对于此类下载和使用导致的任何损坏或数据丢失，您应承担全部责任。
                </p>
                <p>
                    我们可能会不时开发对本应用程序及与之配套的欧乐B 牙刷功能的补丁、漏洞修复、更新、升级和其他修正（以下称为"更新"）。对于本应用程序，这些更新可能未经任何另外的通知或征得任何另外的同意就会自动安装。您知悉并同意这些自动更新。如果您不希望此类自动更新，您可终止您的账户并停止使用本应用程序和/或与之配套的欧乐B 电动牙刷。如果您不终止您此前创建的账户，您将会继续接收自动更新。您知悉并同意您可能被要求安装更新以继续使用应用程序本，且您同意及时安装我们提供的任何更新。
                </p>
                <p>
                    对于与之配套的欧乐B 牙刷的更新，本应用程序会在安装前提示您同意对其进行更新。如果您不希望与之配套的欧乐B 牙刷的此类更新，您可拒绝相应的更新。您继续使用本应用程序及与之配套的欧乐B电动牙刷，即表明您同意上述约定。
                </p>
                <h2>使用规则：
                </h2>
                <p>
                    1. 您可仅出于自身非商业用途而展示来自本应用程序的材料的硬拷贝部分，除另有说明外，还可以电子形式复制、下载和打印该等硬拷贝部分。未经宝洁公司和/或许可内容权利持有者的事先书面许可，不得对本应用程序中的材料进行任何其他使用。本应用程序仅供您个人使用。对于您和您所允许的使用者或访问者对本应用程序的所有使用行为，您须承担全部责任。
                </p>
                <p>
                    2. 不得通过本应用程序发送包含筹款或服务征求信息的内容。
                </p>
                <p>
                    3. 不得通过本应用程序发送包含病毒、特洛伊木马、蠕虫、机器人程序、恶意软件、间谍软件的内容，或其他任何用于损害、干扰或者暗中拦截或入侵任何系统、程序、数据或信息的任何其他计算机代码。
                </p>
                <p>
                    4. 您同意仅将本应用程序用于合法目的，并且您知悉并同意如您未能遵守此规定，则可能需要承担民事和刑事责任。
                </p>
                <p>
                    5. 本应用程序可能包含明确标记为供您使用的图形、文字、照片、图片、视频、音频和其他材料（统称为"资产"）。这些资产受中国和国际版权、商标和其他知识产权法律的保护。不过，我们（和我们的许可方）授予您有限、非专有、不可转让、可撤销的权利和许可，允许通过本应用程序中所述的方式，按照本协议的规定使用这些资产，但您必须确保任何和所有相关版权和其他产权声明完整无缺。
                </p>
                <p>
                    6. 本应用程序还包含由宝洁公司或其许可方提供的但未明确标记为或预期供您使用的其他图形、文字、照片、图片、视频、音频、软件、代码和其他材料，包括但不限于本应用程序的组织结构、设计、编辑物和"外观"以及相关广告（统称为"应用程序内容"）。应用程序内容受中国和国际版权、商标和其他知识产权法律的保护，并且属于宝洁公司或其许可方的财产。严禁您直接或间接对任何应用程序内容进行复制、翻印、出版、展示、重排、重新分配、修改、修订、更改、裁切、重新调整大小、反向工程、移动、移除、删除或用于其他用途或变更，包括但不限于移除或更改广告。宝洁公司授予您有限、非专有、不可转让、可撤销的许可，允许仅出于您自己的非商业个人目的，在指定的兼容移动设备上下载和使用应用程序内容。除本协议中明确规定外，不得对本应用程序内容或本应用程序进行复制、修改、执行、传输、分发、销售，或者创建衍生作品或以其他方式使用或提供。
                </p>
                <p>
                    7. 除非获得版权法或其他法律允许，或者经本协议、宝洁公司或本应用程序明确书面许可，否则您不得通过发布、再传输、分发、执行、缓存、汇总或以其他方式处理通过本应用程序获得的材料（包括但不限于资产或应用程序内容），以任何方式进行商业或其他未经授权的使用。
                </p>
                <p>
                    8. 您同意不以任何方式对本应用程序或其相关软件、硬件和/或服务器进行破坏、重载压迫、攻击、修改、反向工程或干扰，且您同意不阻碍或干扰其他人对本应用程序的使用。
                </p>
                <p>
                    9. 您不得以任何手段企图非法访问宝洁公司的服务器，包括但不限于使用管理员密码或在使用本应用程序时伪装成管理员等方式。
                </p>
                <p>
                    应用程序变更：本应用程序可能会不时修改。如果您未在本应用程序的新版本发布时更新至新版本，您可能无法体验最新材料、功能和/或内容，在此情况下，宝洁公司明确声明不承担任何和所有相关责任。您继续使用本应用程序，即表示您接受本应用程序的任何变更或修订。
                </p>
                <p>
                    违反条款：除了宝洁公司可在您违反本协议条款时获得的任何法律救济外，如果您未遵循这些条款（无论是本使用条款所列规定还是通过本应用程序传达的条款），可能导致在未获通知的情况下暂停或终止您对本应用程序的访问权限。
                </p>
                <p>
                    附加条款和条件：本协议属于<a href="https://www.pg.com/zh_CN/terms_conditions/index.shtml" target="_blank">《宝洁网站消费者使用条款》</a>的一部分，属于“额外条款”。如果本协议与<a href="https://www.pg.com/zh_CN/terms_conditions/index.shtml" target="_blank">《宝洁网站消费者使用条款》</a>有冲突的，以本协议为准；如果本协议与宝洁公司不时出具、更新的专项活动规则、附加条款或补充协议有冲突，以专项活动规则、附加条款或补充协议约定为准。如果任一条款不能被强制执行，不影响其他条款的效力。
                </p>
                <p>
                    免受损害和赔偿：对于因您使用本应用程序引起的或在任何方面与您使用本应用程序有关的任何第三方主张，包括因所有种类和性质的所有索赔、损失、损害（实际和间接损害）、诉讼、判决、诉讼费用和律师费而产生的任何责任或费用，您同意赔偿并确保宝洁公司及其员工、代理和代表不受损害。
                </p>
                <p>
                    双方关系：您提供的信息不应视为代表宝洁公司或者其任何子公司或附属公司的观点。此外，您和宝洁公司之间不存在任何保密或信托等其他关系。
                </p>
                <p>
                    条款修改：宝洁公司可自行酌情决定修改本协议的条款而不另行通知，此类修改应立即生效。因此，我们建议您定期查看此页面，以了解更新的或附加的准则。
                </p>
                <p>
                    法律选择、审判地和司法管辖权：本协议在所有方面均受中华人民共和国法律的管辖并据其解释，不考虑其法律选择规定。您同意，直接或间接因本协议产生的或与本协议有关的任何争议都只能由本协议签署地广州市黄埔区的人民法院解决。您特此不可撤销地同意该审判地，以及任何此类法院对任何此类争议的专属司法管辖权。如果本协议任何规定被具有适格司法管辖权的法院认定无效，则该认定在任何情况下均不影响本协议中其他任何规定的有效性或可执行性。本协议构成双方就本协议主题事项达成的完整协议，并取代双方之前达成的任何和所有协议。选择访问本应用程序的用户也应主动遵循上述规定，并自行负责遵守本地法律（如果本地法律适用，则在适用范围内）。
                </p>
                <p>
                    第三方网站和功能：本应用程序或您从本应用程序收到通信中可能含有指向第三方网站或功能的链接。本应用程序中的图片或评论也可能包含指向第三方网站或功能的链接。本应用程序还可能包含第三方内容，我们不对这些内容进行管控、维护或认可。本应用程序中的功能还可能允许本应用程序与第三方网站或功能之间进行交互，包括将本应用程序或您在本应用程序中的个人资料与第三方网站或功能关联的应用程序。例如本应用程序可能包含支持您与第三方分享本应用程序中的内容或您本身的内容的功能，这些内容可能会公开发布在该第三方服务或应用程序中。使用此功能通常需要您登录到您在该第三方服务的账户，相关风险由您自行承担。我们不对任何此类第三方网络服务或其任何内容进行管控。您明确知悉、确认并同意，我们在任何情况下均不对任何此类第三方服务或功能承担责任。您与通过本应用程序发现的第三方的通信和业务往来仅是您和该第三方之间的事务。您可选择使用可将本应用程序或您在本应用程序中的资料与第三方服务关联的应用程序（由您自行酌情决定并承担全部风险），且此类服务可能与您的应用程序资料进行交互、连接或者信息收集和/或提取。使用此类服务，即表示您承认并同意以下内容：(i) 如果您使用第三方服务来分享信息，则您同意正在分享有关您在本应用程序中资料的信息；(ii) 您对该第三方服务的使用可能导致您的个人身份识别信息公开披露和/或与您关联，即使我们未提供此类信息；且 (iii) 您对该第三方服务的使用由您自行选择并承担相关风险，并且您将针对有关该第三方服务的活动确保我们不受损害。
                </p>
                <p>
                    无保证：本应用程序按"原样"提供，不提供任何形式的保证，您应自行承担因使用服务所产生的风险。宝洁公司明确声明不提供任何有关本应用程序或其内容的明示或默示保证，包括任何有关适销性、适合某特定用途或不侵权的默示保证。
                </p>
                <p>
                    如果您不同意上述条款和条件，请勿下载、浏览、注册、参与或使用本应用程序。
                </p>
            </div>
        )
    }
}

export default Clause;
