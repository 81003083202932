import React, {Component} from "react";
// import cmsRequest from "../../api/cmsRequest";
import Footer from "../../components/Footer"
import logoImg from '../../assets/pampers/logo.png'
import menuImg from '../../assets/pampers/menu.png'
import qrcodeImg from '../../assets/pampers/qrcode.png'
import bgImg from '../../assets/pampers/bg.png'

class Pampers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logoImgItem: {},
            titleImgItem: {},
            mainImgItem: {},
            bgImgItem: {},
            year: 2024,
            ICPCodeItem: {},
            recordCodeItem: {}
        };
    }

    componentDidMount() {
        document.title = '帮宝适官网'
        // cmsRequest.request({ mid: '4d3888cb02' })
        //     .then(result => {
        //         let cmsData = result.data
        //         let logoImgItem = cmsData.find(item => item.id === 'd7d17300ea').content[0]
        //         let titleImgItem = cmsData.find(item => item.id === '009b808617').content[0]
        //         let mainImgItem = cmsData.find(item => item.id === '24db9f5c47').content[0]
        //         let bgImgItem = cmsData.find(item => item.id === '359944ec1b').content[0]
        //         let ICPCodeItem = cmsData.find(item => item.id === 'acb35d1638')
        //         let recordCodeItem = cmsData.find(item => item.id === '1b3b1b5ce3')
        //         this.setState({
        //             logoImgItem,
        //             titleImgItem,
        //             mainImgItem,
        //             bgImgItem,
        //             ICPCodeItem,
        //             recordCodeItem
        //         })
        //     })
        //     .catch(err => {
        //         console.log('data catch', err)
        //     })
        
        // cmsRequest.request({ mid: '494d6de189' })
        //     .then(result => {
        //         let cmsData = result.data
        //         let year = cmsData.find(item => item.id === '63a92cfade').content
        //         this.setState({
        //             year
        //         })
        //     })
        //     .catch(err => {
        //         console.log('year catch', err)
        //     })

        // temp
        let logoImgItem = {
            isShow: true,
            imageUrl: logoImg,
            parameters: [
                {
                    value: '帮宝适Logo'
                }
            ]
        }
        let titleImgItem = {
            isShow: true,
            imageUrl: menuImg,
            parameters: [
                {
                    value: '帮宝适会员俱乐部，理解扫一扫，加入帮宝适俱乐部。 0元试用，专属咨询，优惠红包，积分换礼。'
                }
            ]
        }
        let mainImgItem = {
            isShow: true,
            imageUrl: qrcodeImg,
            parameters: [
                {
                    value: '实际福利以当期会员活动为准'
                }
            ]
        }
        let bgImgItem = {
            isShow: true,
            imageUrl: bgImg,
        }
        let ICPCodeItem = {
            isShow: true,
            content: '粤ICP备05017514号',
            clickActionParameters: {
                url: 'https://beian.miit.gov.cn/#/Integrated/index'
            }
        }
        let recordCodeItem = {
            isShow: true,
            content: '粤公网安备44011602000383号',
            clickActionParameters: {
                url: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011602000383'
            }
        }
        this.setState({
            logoImgItem,
            titleImgItem,
            mainImgItem,
            bgImgItem,
            ICPCodeItem,
            recordCodeItem
        })
    }

    render() {
        let { logoImgItem, titleImgItem, mainImgItem, bgImgItem, year, ICPCodeItem, recordCodeItem } = this.state
        return (
            <div className="homePage pampers">
                <div className="container" style={{backgroundImage: `url(${bgImgItem.imageUrl})`}}>
                    {
                        logoImgItem.isShow && (
                            <img className="logoImg" src={logoImgItem.imageUrl} alt={logoImgItem.parameters[0].value}></img>
                        )
                    }
                    {
                        titleImgItem.isShow && (
                            <img className="menuImg" src={titleImgItem.imageUrl} alt={titleImgItem.parameters[0].value}></img>
                        )
                    }
                    {
                        mainImgItem.isShow && (
                            <img className="qrCodeImg" src={mainImgItem.imageUrl} alt={mainImgItem.parameters[0].value}></img>
                        )
                    }
                </div>
                {
                    ICPCodeItem.isShow && recordCodeItem.isShow && (
                        <Footer ICPCodeItem={ICPCodeItem} recordCodeItem={recordCodeItem} year={year}></Footer>
                    )
                }
            </div>
        )
    }
}

export default Pampers;