import React, { Component } from "react";
import cmsRequest from "../../api/cmsRequest";
import Footer from "../../components/Footer"

class Safeguard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mainImgItem: {},
            year: 2024,
            ICPCodeItem: {},
            recordCodeItem: {}
        };
    }

    async componentDidMount() {
        document.title = '舒肤佳官网'
        cmsRequest.request({ mid: 'b091ad154b' })
            .then(result => {
                let cmsData = result.data
                let mainImgItem = cmsData.find(item => item.id === '77648319e5').content[0]
                let ICPCodeItem = cmsData.find(item => item.id === '3983282475')
                let recordCodeItem = cmsData.find(item => item.id === '9d89eee87f')
                this.setState({
                    mainImgItem,
                    ICPCodeItem,
                    recordCodeItem
                })
            })
            .catch(err => {
                console.log('data catch', err)
            })
        
        cmsRequest.request({ mid: '494d6de189' })
            .then(result => {
                let cmsData = result.data
                let year = cmsData.find(item => item.id === '63a92cfade').content
                this.setState({
                    year
                })
            })
            .catch(err => {
                console.log('year catch', err)
            })
    }

    render() {
        let { mainImgItem, ICPCodeItem, recordCodeItem, year } = this.state
        return (
            <div className="homePage">
                {
                    mainImgItem.isShow && (
                        <img className="mainImg" src={mainImgItem.imageUrl} alt={mainImgItem.parameters[0].value}></img>
                    )
                }
                {
                    ICPCodeItem.isShow && recordCodeItem.isShow && (
                        <Footer ICPCodeItem={ICPCodeItem} recordCodeItem={recordCodeItem} year={year}></Footer>
                    )
                }
            </div>
        )
    }
}

export default Safeguard;