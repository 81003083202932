let RoutePath = {
    Index: '/',
    Safeguard:'/safeguard',
    Whisper:'/whisper',
    Rejoice:'/rejoice',
    Vs:'/vs',
    Pantene:'/pantene',
    HeadShoulders:'/headShoulders',
    Shenghuojia:'/shenghuojia',
    Downy:'/downy',
    Crest: '/crest',
    Oralb: '/oralb',
    Pampers: '/pampers',
    Gillette: '/gillette',
    Braun: '/braun',
    ServiceAddress: '/serviceAddress',
    StoreAddress: '/storeAddress',
    Olay: '/olay',
    Gillettevector2: '/gillettevector2',
    Gillettevector3: '/gillettevector3',
    Clause: '/clause',
    Privacy: '/privacy',
    Dmt_privacy: '/dmt_privacy'
};

export default RoutePath;
