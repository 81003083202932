import React, {Component} from "react";
import cmsRequest from "../../api/cmsRequest";
import Footer from "../../components/Footer"

class Downy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logoImgItem: {},
            titleImgItem: {},
            mainImgItem: {},
            bgImgItem: {},
            year: 2024,
            ICPCodeItem: {},
            recordCodeItem: {}
        };
    }

    componentDidMount() {
        document.title = 'Downy 微信二维码'
        cmsRequest.request({ mid: '6548862248' })
            .then(result => {
                let cmsData = result.data
                let logoImgItem = cmsData.find(item => item.id === 'd0983be549').content[0]
                let titleImgItem = cmsData.find(item => item.id === '1f0999faef').content[0]
                let mainImgItem = cmsData.find(item => item.id === '321d4815aa').content[0]
                let bgImgItem = cmsData.find(item => item.id === '75bf5104a1').content[0]
                let ICPCodeItem = cmsData.find(item => item.id === '315c14d3ab')
                let recordCodeItem = cmsData.find(item => item.id === 'af57f7f93a')
                this.setState({
                    logoImgItem,
                    titleImgItem,
                    mainImgItem,
                    bgImgItem,
                    ICPCodeItem,
                    recordCodeItem
                })
            })
            .catch(err => {
                console.log('data catch', err)
            })
        
        cmsRequest.request({ mid: '494d6de189' })
            .then(result => {
                let cmsData = result.data
                let year = cmsData.find(item => item.id === '63a92cfade').content
                this.setState({
                    year
                })
            })
            .catch(err => {
                console.log('year catch', err)
            })
    }

    render() {
        let { logoImgItem, titleImgItem, mainImgItem, bgImgItem, year, ICPCodeItem, recordCodeItem } = this.state
        return (
            <div className="homePage downy">
                <div className="container">
                    <div className="mainBox">
                        {
                            logoImgItem.isShow && (
                                <img className="logoImg" src={logoImgItem.imageUrl} alt={logoImgItem.parameters[0].value}></img>
                            )
                        }
                        {
                            titleImgItem.isShow && (
                                <img className="titleImg" src={titleImgItem.imageUrl} alt={titleImgItem.parameters[0].value}></img>
                            )
                        }
                        {
                            mainImgItem.isShow && (
                                <img className="qrCodeImg" src={mainImgItem.imageUrl} alt={mainImgItem.parameters[0].value}></img>
                            )
                        }
                    </div>
                    {
                        bgImgItem.isShow && (
                            <img className="bgImg" src={bgImgItem.imageUrl}></img>
                        )
                    }
                </div>
                {
                    ICPCodeItem.isShow && recordCodeItem.isShow && (
                        <Footer ICPCodeItem={ICPCodeItem} recordCodeItem={recordCodeItem} year={year}></Footer>
                    )
                }
            </div>
        )
    }
}

export default Downy;