import React, { Component } from "react";
import './Dmt_privacy.css'

class Dmt_privacy extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        document.title = ''
    }

    render() {
        let {} = this.state
        return (
            <div className="dmt-privacy">
                <iframe src='dmt_privacy.html' width={'100%'}  height={'100%'} style={{border: 0, height: '100vh'}} />
            </div>
        )
    }
}

export default Dmt_privacy;
