import React, { Component } from "react";

import logoImg from '../../assets/braun/logo.jpg'
var Sheet1 = [
    {
        store: "北区",
        storeType: "",
        city: "",
        address: "",
        time: "",
    },
    {
        store: "Ole 北京合生汇店",
        storeType: "大卖场超市",
        city: "北京市",
        address: "北京市西大望路 21号合生汇购物中心 B2",
        time: "10:00 - 22:00",
    },
    {
        store: "北京 SKP",
        storeType: "百货商场",
        city: "北京市",
        address: "北京市朝阳区建国路 87号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京百盛购物中心",
        storeType: "百货商场",
        city: "北京市",
        address: "北京市西城区阜兴门内大街 101号 4F",
        time: "10:00 - 21:30",
    },
    {
        store: "北京城乡华懋",
        storeType: "百货商场",
        city: "北京市",
        address: "北京市海淀区复兴路甲 23 号城乡华懋 5 楼 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京翠微大成店",
        storeType: "百货商场",
        city: "北京市",
        address: "北京市丰台区大成路 8 号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京翠微大厦",
        storeType: "百货商场",
        city: "北京市",
        address: "北京市海淀区复兴路 33 号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京翠微牡丹园店",
        storeType: "百货商场",
        city: "北京市",
        address: "北京市海淀区花园路 2 号 3F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京大红门银泰百货",
        storeType: "百货商场",
        city: "北京市",
        address: "北京市丰台区马家堡东路101号院 10 号商业楼银泰百货4F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京当代商城",
        storeType: "百货商场",
        city: "北京市",
        address: "北京市海淀区海淀路130 号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京甘家口大厦",
        storeType: "百货商场",
        city: "北京市",
        address: "北京市西城区三里河路 37号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京汉光百货",
        storeType: "百货商场",
        city: "北京市",
        address: "北京市西城区西单北大街 176 号7F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京金源燕莎店",
        storeType: "百货商场",
        city: "北京市",
        address: "北京市海淀区远大路1号海淀区世纪金源购物中心 B1F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京京北大世界",
        storeType: "百货商场",
        city: "北京市",
        address: "北京市怀柔区府前街 14 号京北大世界 5F",
        time: "09:00 - 21:00",
    },
    {
        store: "北京蓝岛大厦",
        storeType: "百货商场",
        city: "北京市",
        address: "北京市朝阳区朝外大街 8 号 3F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京清河万象汇",
        storeType: "购物中心",
        city: "北京市",
        address: "北京市海淀区清河中街 68 号西区L2K13",
        time: "10:00 - 22:00",
    },
    {
        store: "北京双安商场",
        storeType: "百货商场",
        city: "北京市 ",
        address: "北京市海淀区北三环西路 38 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京王府井百货大楼",
        storeType: "百货商场",
        city: "北京市 ",
        address: "北京市王府井大街 253 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京西单商场",
        storeType: "百货商场",
        city: "北京市 ",
        address: "北京市西城区西单北大街 120 号 B1F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京新世界商场",
        storeType: "百货商场",
        city: "北京市",
        address:
            "北京市崇文区崇文门外大街 3 号北京新世界北京市商场一期综合馆 3F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京燕莎奥特莱斯",
        storeType: "购物中心",
        city: "北京市 ",
        address: "北京市朝阳区东四环南路九号燕莎奥特莱斯 C 座 3F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京燕莎友谊商城",
        storeType: "百货商场",
        city: "北京市 ",
        address: "北京市朝阳区亮马桥路 52 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "北京长安商场",
        storeType: "百货商场",
        city: "北京市 ",
        address: "北京市西城区复兴门外大街 15 号 B1F",
        time: "10:00 - 22:00",
    },
    {
        store: "石家庄北国商城家电商场",
        storeType: "百货商场",
        city: "石家庄市",
        address: "河北省石家庄市中山东路188 号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "石家庄北国商厦家居精品商场",
        storeType: "百货商场",
        city: "家庄市 ",
        address: "河北省石家庄市中山东路188 号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "石家庄先天下广场百货",
        storeType: "百货商场",
        city: "家庄市 ",
        address: "河北省石家庄市中山东路 326 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "石家庄新百广场百货",
        storeType: "百货商场",
        city: "家庄市 ",
        address: "河北省石家庄市中山东路139 号 2F",
        time: "10:00 - 22:00",
    },
    {
        store: "唐山百货电器商场",
        storeType: "百货商场",
        city: "唐山市",
        address:
            "河北省唐山市河北省唐山市路北区新华东道 125 号唐山市唐百电器 2F",
        time: "09:00 - 20:00",
    },
    {
        store: "邢台天一购物广场",
        storeType: "百货商场",
        city: "邢台市",
        address: "河北省邢台市河北省邢台市桥东区新华北路 235 号A 座 4F",
        time: "09:30 - 21:30",
    },
    {
        store: "丹尼斯百货大卫城店",
        storeType: "百货商场",
        city: "郑州市 ",
        address: "河南省郑州市金水区二七路丹尼斯大卫城 8F",
        time: "10:00 - 22:00",
    },
    {
        store: "郑州安阳丹尼斯百货",
        storeType: "百货商场",
        city: "安阳市 ",
        address: "河南省安阳市安阳市三道街与文峰路交叉口 3F",
        time: "09:30 - 21:30",
    },
    {
        store: "郑州丹尼斯 CBD三天地店",
        storeType: "百货商场",
        city: "郑州市",
        address: "河南省郑州市郑东新区商务内环与商务西三街交叉口 - 1F",
        time: "09:30 - 21:30",
    },
    {
        store: "郑州丹尼斯 GMS 店",
        storeType: "百货商场",
        city: "郑州市",
        address: "河南省郑州市郑东新区商务内环与众意西路交叉口 3F ",
        time: "09:00 - 21:30",
    },
    {
        store: "郑州丹尼斯百货花园店",
        storeType: "百货商场",
        city: "郑州市",
        address: "河南省郑州市金水区花园路与农业路交叉口东北角12F",
        time: "09:30 - 21:30",
    },
    {
        store: "郑州丹尼斯百货人民店",
        storeType: "百货商场",
        city: "郑州市",
        address: "河南省郑州市人民路 2 号 6F",
        time: "09:30 - 21:30",
    },
    {
        store: "郑州洛阳长申国际",
        storeType: "百货商场",
        city: "洛阳市 ",
        address: "河南省洛阳市丽新路与辽宁路交叉口长申国际 - 1F",
        time: "09:00 - 22:00",
    },
    {
        store: "长春欧亚卖场",
        storeType: "百货商场",
        city: "长春市 ",
        address: "吉林省长春市开运街 5178 号 - 2F",
        time: "09:30 - 20:00",
    },
    {
        store: "长春欧亚商都百货",
        storeType: "百货商场",
        city: "长春市 ",
        address: "吉林省长春市工农大路1128 号7F",
        time: "09:30 - 21:00",
    },
    {
        store: "Ole 沈阳万象城店",
        storeType: "大卖场超市",
        city: "沈阳市 ",
        address: "辽宁省沈阳市和平区青年大街 288 号万象城 B1",
        time: "10:00 - 22:00",
    },
    {
        store: "大连大商百货",
        storeType: "百货商场",
        city: "大连市 ",
        address: "辽宁省大连市中山区青三街 1号北三楼 3F",
        time: "09:00 - 21:00",
    },
    {
        store: "大连麦凯乐开发区店百货",
        storeType: "百货商场",
        city: "大连市 ",
        address: "辽宁省大连市开发区金马路198 号 3F",
        time: "09:00 - 21:00",
    },
    {
        store: "大连麦凯乐西安路店百货",
        storeType: "百货商场",
        city: "大连市 ",
        address: "辽宁省大连市沙河口区西安路123 号 5F",
        time: "09:00 - 21:00",
    },
    {
        store: "大连麦凯乐总店百货",
        storeType: "百货商场",
        city: "大连市 ",
        address: "辽宁省大连市中山区友好街 42 号 6F",
        time: "09:00 - 21:00",
    },
    {
        store: "大连友谊开发区店百货",
        storeType: "百货商场",
        city: "大连市 ",
        address: "辽宁省大连市金州区本溪街 3 - 2 号 5F",
        time: "09:00 - 21:00",
    },
    {
        store: "大连友谊商城本店",
        storeType: "百货商场",
        city: "大连市 ",
        address: "辽宁省大连市中山区人民路 8 号 8F",
        time: "09:00 - 21:00",
    },
    {
        store: "沈阳万象城",
        storeType: "购物中心",
        city: "沈阳市",
        address: "辽宁省沈阳市和平区青年大街 288 号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "中兴 - 沈阳商业大厦",
        storeType: "百货商场",
        city: "沈阳市",
        address: "辽宁省沈阳市和平区太原北街 86 号 6F",
        time: "09:30 - 21:30",
    },
    {
        store: "包头王府井百货昆区店",
        storeType: "百货商场",
        city: "包头市",
        address: "内蒙古自治区包头市昆都仑区钢铁大街 69 号王府井百货 5F",
        time: "10:00 - 20:00",
    },
    {
        store: "内蒙鄂尔多斯王府井商厦",
        storeType: "百货商场",
        city: "鄂尔多斯市",
        address: "内蒙古自治区鄂尔多斯市东胜区王府井金街 0F",
        time: "10:00 - 20:00",
    },
    {
        store: "内蒙古维多利购物中心",
        storeType: "百货商场",
        city: "呼和浩特市",
        address: "内蒙古自治区呼和浩特市呼和浩特市回民区中山西路1号 5F",
        time: "10:00 - 21:00",
    },
    {
        store: "内蒙古维多利国际广场",
        storeType: "百货商场",
        city: "呼和浩特市",
        address:
            "内蒙古自治区呼和浩特市新城区东护城河南街维多利国际广场 - 1F",
        time: "10:00 - 21:00",
    },
    {
        store: "Ole 济南恒隆广场店",
        storeType: "大卖场超市",
        city: "济南市",
        address: "山东省济南市泉城路恒隆广场东塔 3F",
        time: "10:00 - 22:00",
    },
    {
        store: "Ole 济南万象城店",
        storeType: "大卖场超市",
        city: "济南市",
        address: "山东省济南市历下区经十路11111号济南华润万象城 LG1",
        time: "10:00 - 22:00",
    },
    {
        store: "济南贵和购物中心",
        storeType: "百货商场",
        city: "济南市",
        address: "山东省济南市济南天地坦街 1号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "济南临沂银座",
        storeType: "百货商场",
        city: "临沂市",
        address: "山东省临沂市兰山区红旗路13 号 - 1F",
        time: "09:30 - 21:30",
    },
    {
        store: "济南万象城",
        storeType: "购物中心",
        city: "济南市",
        address: "山东省济南市经十路11111号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "济南银座商城",
        storeType: "百货商场",
        city: "济南市",
        address: "山东省济南市历下区泺源大街 55 号 2F",
        time: "09:30 - 21:30",
    },
    {
        store: "济南玉函银座",
        storeType: "百货商场",
        city: "济南市",
        address: "山东省济南市中区经十路19288 号地 - 1F",
        time: "09:30 - 21:30",
    },
    {
        store: "济南淄博银座",
        storeType: "百货商场",
        city: "淄博市",
        address: "山东省淄博市张店区柳泉路 128 号(共青团路口)5F",
        time: "09:30 - 21:30",
    },
    {
        store: "青岛海信广场",
        storeType: "百货商场",
        city: "青岛市 ",
        address: "山东省青岛市市南区澳门路 117 号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "青岛永旺东泰商业有限公司东部店",
        storeType: "购物中心 ",
        city: "青岛市",
        address: "山东省青岛市市南区香港中路 72 号 2F",
        time: "08:00 - 22:00",
    },
    {
        store: "威海振华商厦",
        storeType: "百货商场 ",
        city: "威海市",
        address: "山东省威海市环翠区新威路 89 号 - 2F",
        time: "09:30 - 21:30",
    },
    {
        store: "潍坊中百大厦",
        storeType: "百货商场 ",
        city: "潍坊市",
        address: "山东省潍坊市潍城区胜利西街 233 号 5F",
        time: "09:30 - 21:30",
    },
    {
        store: "烟台振华商厦",
        storeType: "百货商场 ",
        city: "烟台市",
        address: "山东省烟台市芝罘区西大街 8 号西区 6F",
        time: "09:30 - 21:30",
    },
    {
        store: "Ole 太原万象城",
        storeType: "大卖场超市",
        city: "太原市",
        address: "山西省山西省太原市万柏林区长兴路 5 号 B1",
        time: "10:00 - 22:00",
    },
    {
        store: "北京王府井百货太原店",
        storeType: "百货商场 ",
        city: "太原市",
        address: "山西省太原市亲贤街 99 号 - 1F",
        time: "10:00 - 22:00",
    },
    {
        store: "大同百盛",
        storeType: "百货商场 ",
        city: "大同市",
        address: "山西省大同市平城区永泰南路百盛 4F",
        time: "09:30 - 21:30",
    },
    {
        store: "太原天美新天地",
        storeType: "百货商场 ",
        city: "太原市",
        address: "山西省太原市长风街 113 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "西安大融城",
        storeType: "购物中心",
        city: " 西安市",
        address:
            "陕西省西安市未央区凤城八路大融城 3 楼\r\n乐己时光集合店内 3F",
        time: "10:00 - 21:30",
    },
    {
        store: "西安高新金花百货",
        storeType: "百货商场 ",
        city: "西安市",
        address: "陕西省西安市高新区科技路 33 号高新国际商务中心 4 层 3F",
        time: "10:00 - 21:30",
    },
    {
        store: "西安华联 skp",
        storeType: "百货商场 ",
        city: "西安市",
        address: "陕西省西安市碑林区长安北路 261 号 6F",
        time: "10:00 - 21:30",
    },
    {
        store: "西安开元商城",
        storeType: "百货商场 ",
        city: "西安市",
        address: "陕西省西安市解放路市场 6 号 6F",
        time: "10:00 - 21:30",
    },
    {
        store: "西安王府井百货南门店",
        storeType: "百货商场 ",
        city: "西安市",
        address:
            "陕西省西安市碑林区南关正街 88 号长安国际中心王府井百货 - 1F",
        time: "10:00 - 21:30",
    },
    {
        store: "西安小寨赛格二楼",
        storeType: "购物中心 ",
        city: "西安市",
        address: "陕西省西安市雁塔区长安中路 123 号 2F",
        time: "10:00 - 21:30",
    },
    {
        store: "西安中大国际高新店",
        storeType: "购物中心 ",
        city: "西安市",
        address: "陕西省西安市高新路 72 号 - 1F",
        time: "10:00 - 21:30",
    },
    {
        store: "天津大悦城 BLT 店",
        storeType: "大卖场超市 ",
        city: "天津市",
        address: "天津市南开区南门外大街南开大悦城 B1 层\r\n华润 BLT 超市 1F",
        time: "10:00 - 22:00",
    },
    {
        store: "天津海信广场百货",
        storeType: "百货商场 ",
        city: "天津市 ",
        address: "天津市和平区解放南路 188 号 5F",
        time: "周日 - 周四\r\n10:00 - 21:30\r\n周五 - 周六\r\n10:00 - 22:00",
    },
    {
        store: "天津金元宝爱来无忧数码电器广场",
        storeType: "百货商场 ",
        city: "天津市",
        address: "天津市滨海新区东大街 355 号 1F",
        time: "09:00 - 21:00",
    },
    {
        store: "天津金元宝滨海国际",
        storeType: "百货商场 ",
        city: "天津市",
        address: "天津市滨海新区第二大街黄海路 19 号 3F",
        time: "09:30 - 21:00",
    },
    {
        store: "天津友谊商厦",
        storeType: "百货商场 ",
        city: "天津市",
        address: "天津市河西区友谊路 21 号 4F",
        time: "10:00 - 21:00",
    },
    {
        store: "东区",
        storeType: " ",
        city: " ",
        address: "",
        time: "",
    },
    {
        store: "合肥滨湖银泰城",
        storeType: "购物中心 ",
        city: "合肥市",
        address: "合肥市包河区云谷路 1718 号",
        time: "10:00 - 21:30",
    },
    {
        store: "合肥商业大厦百货",
        storeType: "百货商场 ",
        city: "合肥市",
        address: " 合肥市包河区金寨路 1168 号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "蚌埠银泰城民意感觉生活",
        storeType: "购物中心 ",
        city: "蚌埠市",
        address: "安徽省蚌埠市东海大道与延安路交口银泰城 - 1F",
        time: "10:00 - 22:00",
    },
    {
        store: "合肥 CBD 百货",
        storeType: "百货商场 ",
        city: "合肥市",
        address: "安徽省合肥市长江西路 360 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "合肥蚌埠百货大楼",
        storeType: "百货商场 ",
        city: "蚌埠市",
        address: "安徽省蚌埠市蚌山区淮河路 968 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "合肥包河万达百货",
        storeType: "百货商场 ",
        city: "合肥市",
        address: "安徽省合肥市包河区马鞍山路 130 号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "合肥大洋百货",
        storeType: "百货商场 ",
        city: "合肥市",
        address: "安徽省合肥市长江西路 199 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "合肥鼓楼百货",
        storeType: "百货商场 ",
        city: "合肥市",
        address: "安徽省合肥市宿州路 96 号 7F",
        time: "10:00 - 22:00",
    },
    {
        store: "合肥乐普生百货",
        storeType: "百货商场 ",
        city: "合肥市",
        address: "安徽省合肥市长江中路 150 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "合肥庐阳万象汇感觉生活",
        storeType: "购物中心 ",
        city: "合肥市",
        address: "安徽省合肥市临泉路与凤凰山路交口 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "合肥商之都国生电器百货",
        storeType: "百货商场 ",
        city: "合肥市",
        address: "安徽省合肥市宿州路 8 号 - 1F",
        time: "10:00 - 22:00",
    },
    {
        store: "合肥铜陵百大购物中心",
        storeType: "百货商场 ",
        city: "铜陵市",
        address: "安徽省铜陵市安徽省铜陵市义安南路 43 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "合肥万象城",
        storeType: "购物中心 ",
        city: "合肥市",
        address: "安徽省合肥市政务区潜山路与习友路交口 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "合肥唯品会城市奥莱",
        storeType: "购物中心",
        city: "合肥市",
        address: "安徽省合肥市南二环与金寨路交口安粮城市广场 - 1F",
        time: "10:00 - 22:00",
    },
    {
        store: "合肥新华书店图书城店",
        storeType: "购物中心 ",
        city: "合肥市",
        address: "安徽省合肥市庐阳区长江中路 279 号 3F",
        time: "10:00 - 22:00",
    },
    {
        store: "合肥银泰百货",
        storeType: "百货商场 ",
        city: "合肥市",
        address: "安徽省合肥市长江中路 98 号 - 1F",
        time: "10:00 - 22:00",
    },
    {
        store: "合肥银泰城百货",
        storeType: "购物中心 ",
        city: "合肥市",
        address: "安徽省合肥市蜀山区荷叶地街道绿怡居社区潜山路 190 号",
        time: "10:00 - 22:00",
    },
    {
        store: "六安百大金商都购物中心",
        storeType: "百货商场 ",
        city: "六安市",
        address: "安徽省六安市安徽省六安市金安区皖西路与梅山路交口 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "马鞍山金鹰国际购物中心",
        storeType: "百货商场 ",
        city: "合肥市",
        address: "安徽省合肥市马鞍山市花山区江东大道 1480 号-1F",
        time: "10:00 - 22:00",
    },
    {
        store: "湖北宜昌国贸百货",
        storeType: "大卖场超市 ",
        city: "武汉市",
        address: "湖北省武汉市宜昌西陵区东山大道 18 号 - 1F",
        time: "08:00 - 22:00",
    },
    {
        store: "武汉 BHG 超市群星城店",
        storeType: "大卖场超市 ",
        city: "武汉市",
        address: "湖北省武汉市市洪山区徐东大街 120 号群星城 B1",
        time: "10:00 - 22:00",
    },
    {
        store: "武汉国际广场百货",
        storeType: "百货商场 ",
        city: "武汉市",
        address: "湖北省武汉市解放大道 690 号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "武汉群光广场百货",
        storeType: "百货商场 ",
        city: "武汉市",
        address: "湖北省武汉市洪山区珞瑜路 6 号 6F",
        time: "10:00 - 21:30",
    },
    {
        store: "武汉市青山众圆广场百货",
        storeType: "百货商场 ",
        city: "武汉市",
        address: "湖北省武汉市市青山区和平大道建设三路（众圆广场）4F",
        time: "10:00 - 22:00",
    },
    {
        store: "武汉武商广场百货",
        storeType: "百货商场 ",
        city: "武汉市",
        address: "湖北省武汉市武汉解放大道 688 号 7F",
        time: "10:00 - 22:00",
    },
    {
        store: "武汉新世界国贸店百货",
        storeType: "百货商场 ",
        city: "武汉市",
        address: "湖北省武汉市建设大道 566 号 1F",
        time: "10:00 - 22:00",
    },
    {
        store: "武汉壹方购物中心",
        storeType: "购物中心 ",
        city: "武汉市",
        address: "湖北省武汉市市江岸区中山大道 1515 号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "武汉中商广场百货",
        storeType: "百货商场 ",
        city: "武汉市",
        address: "湖北省武汉市中南路 9 号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "武商黄石购物中心百货",
        storeType: "百货商场 ",
        city: "黄石市",
        address: "湖北省黄石市黄石中心购物广场南京路８号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "襄阳华洋堂百货",
        storeType: "百货商场 ",
        city: "襄阳市",
        address: "湖北省襄阳市樊城区长征路 36 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "襄阳武商购物中心",
        storeType: "购物中心 ",
        city: "襄阳市",
        address: "湖北省襄阳市樊城区汉江街道长虹路 13 号武商百货 4F",
        time: "09:30 - 22:00",
    },
    {
        store: "Ole 长沙国金中心店",
        storeType: "大卖场超市 ",
        city: "长沙市",
        address: "湖南省长沙市芙蓉区解放西路 188 号长沙国金中心 LG1",
        time: "10:00 - 22:00",
    },
    {
        store: "长沙友谊商店袁家岭店",
        storeType: "百货商场 ",
        city: "长沙市 ",
        address: "湖南省长沙市芙蓉区五一大道 368 号友谊商店",
        time: "09:30 - 22:00",
    },
    {
        store: "贵阳国贸广场店",
        storeType: "百货商场 ",
        city: "贵阳市 ",
        address: "贵州省贵阳市贵阳市中华中路 1 号 - 1F",
        time: "10:00 - 22:00",
    },
    {
        store: "贵阳万象汇",
        storeType: "购物中心 ",
        city: "贵阳市 ",
        address: "贵阳市观山湖区兴筑西路 88 号万象汇 L454",
        time: "10:00 - 22:00",
    },
    {
        store: "常州购物中心百货",
        storeType: "百货商场 ",
        city: "常州市 ",
        address: "江苏省常州市天宁区延陵西路 1 - 7 号 7F",
        time: "10:00 - 22:00",
    },
    {
        store: "常州泰富百货",
        storeType: "百货商场 ",
        city: "常州市 ",
        address: "江苏省常州市钟楼区延陵西路 119 - 118 号 10F",
        time: "10:00 - 22:00",
    },
    {
        store: "常州新世纪百货",
        storeType: "百货商场 ",
        city: "常州市",
        address: "江苏省常州市钟楼区北大街 2 - 8 号 7F",
        time: "10:00 - 22:00",
    },
    {
        store: "江苏泰州金鹰百货",
        storeType: "百货商场",
        city: "泰州市",
        address: "江苏省泰州市海陵区东进东路 18 号金鹰国际购物中心 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "江阴华联百货",
        storeType: "百货商场 ",
        city: "江阴市 ",
        address: "江苏省江阴市人民中路 85 号 8F",
        time: "10:00 - 22:00",
    },
    {
        store: "南京大洋百货江北店",
        storeType: "百货商场 ",
        city: "南京市 ",
        address: "江苏省南京市江北新区大桥北路 48 号 1F",
        time: "10:00 - 22:00",
    },
    {
        store: "南京虹悦城",
        storeType: "购物中心 ",
        city: "南京市 ",
        address: "江苏省南京市雨花台区应天大街 619 号 - 1F",
        time: "10:00 - 22:00",
    },
    {
        store: "南京建邺金鹰购物中心",
        storeType: "购物中心",
        city: "南京市",
        address: "江苏省南京市建邺区应天大街 888 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "南京江宁金鹰购物中心",
        storeType: "百货商场",
        city: "南京市",
        address: "江苏省南京市江宁区双龙大道 1700 号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "南京金鹰新街口购物中心",
        storeType: "购物中心",
        city: "南京市",
        address: "江苏省南京市白下区汉中路 89 号 B 栋 7楼",
        time: "10:00 - 22:00",
    },
    {
        store: "南京金鹰新街口购物中心",
        storeType: "购物中心",
        city: "南京市",
        address: "江苏省南京市白下区汉中路 89 号 A 栋 5 楼",
        time: "10:00 - 22:00",
    },
    {
        store: "南京金鹰珠江路购物中心",
        storeType: "百货商场",
        city: "南京市",
        address: "江苏省南京市玄武区珠江路1号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "南京苏宁淮海路店",
        storeType: "电器连锁",
        city: "南京市",
        address: "江苏省南京市白下区淮海路 68 号 2F",
        time: "10:00 - 22:00",
    },
    {
        store: "南京仙林金鹰购物中心",
        storeType: "购物中心",
        city: "南京市",
        address: "江苏省南京市栖霞区学海路1号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "南京新百",
        storeType: "百货商场",
        city: "南京市",
        address: "江苏省南京市中山南路 3 号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "南京中央商场",
        storeType: "百货商场",
        city: "南京市",
        address: "江苏省南京市白下区中山南路 79 号7F",
        time: "10:00 - 22:00",
    },
    {
        store: "南通文峰大世界百货",
        storeType: "百货商场",
        city: "南通市",
        address: "江苏省南通市崇川区南大街 3 - 21号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "苏州久光百货",
        storeType: "百货商场",
        city: "苏州市",
        address: "江苏省苏州市工业园区旺墩路 268 号 2F",
        time: "10:00 - 22:00",
    },
    {
        store: "苏州昆山商厦百货",
        storeType: "百货商场",
        city: "昆山市",
        address: "江苏省昆山市人民南路 96 - 2 号 2F",
        time: "10:00 - 22:00",
    },
    {
        store: "苏州美罗百货",
        storeType: "百货商场",
        city: "苏州市",
        address: "江苏省苏州市姑苏区观前街 245 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "苏州人民商场百货",
        storeType: "百货商场",
        city: "苏州市",
        address: "江苏省苏州市姑苏区北局路 22 号 2F",
        time: "10:00 - 22:00",
    },
    {
        store: "苏州石路国际百货",
        storeType: "百货商场",
        city: "苏州市",
        address: "江苏省苏州市姑苏区石路18 号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "苏州泰华百货",
        storeType: "百货商场",
        city: "苏州市",
        address: "江苏省苏州市沧浪区人民路 383 号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "苏州天虹百货",
        storeType: "百货商场",
        city: "苏州市",
        address: "江苏省苏州市工业园区苏雅路 388 号 3F",
        time: "10:00 - 22:00",
    },
    {
        store: "苏州新区美罗百货",
        storeType: "百货商场",
        city: "苏州市",
        address: "江苏省苏州市高新区长江路 211号 - 1F",
        time: "10:00 - 22:00",
    },
    {
        store: "无锡八佰伴百货",
        storeType: "百货商场",
        city: "无锡市",
        address: "江苏省无锡市崇安区中山路168 号7F",
        time: "10:00 - 22:00",
    },
    {
        store: "无锡商业大厦百货",
        storeType: "百货商场",
        city: "无锡市",
        address: "江苏省无锡市崇安区中山路 343 号7F",
        time: "10:00 - 22:00",
    },
    {
        store: "无锡市江阴华地百货商场",
        storeType: "百货商场",
        city: "江阴市",
        address: "江苏省江阴市人民中路18 号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "无锡镇江八佰伴百货",
        storeType: "百货商场",
        city: "镇江市",
        address: "江苏省镇江市中山东路 288 号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "徐州金鹰国际购物中心百货",
        storeType: "百货商场",
        city: "徐州市",
        address: "江苏省徐州市中央北路1号彭城广场北侧 7F",
        time: "10:00 - 22:00",
    },
    {
        store: "扬州金鹰",
        storeType: "百货商场",
        city: "扬州市",
        address: "江苏省扬州市广陵区汶河南路120 文昌店金鹰 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "宜兴八佰伴百货",
        storeType: "百货商场",
        city: "无锡市",
        address:
            "江苏省无锡市江苏省无锡市宜兴市解放东路与枫隐路交汇处（万达广场）3F",
        time: "10:00 - 22:00",
    },
    {
        store: "张家港市曼巴特购物广场",
        storeType: "百货商场",
        city: "苏州市",
        address: "江苏省苏州市江苏省张家港市南门路1号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "南昌百货大楼百货",
        storeType: "百货商场",
        city: "南昌市",
        address: "江西省南昌市东湖区中山路1号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "南昌财富广场百货",
        storeType: "百货商场",
        city: "南昌市",
        address: "江西省南昌市八一大道 224 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "南昌洪客隆百货",
        storeType: "百货商场",
        city: "南昌市",
        address: "江西省南昌市八一大道 324 号 - 2F",
        time: "10:00 - 22:00",
    },
    {
        store: "南昌九江联盛店",
        storeType: "百货商场",
        city: "九江市",
        address: "江西省九江市浔龙区252 号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海百联又一城百货",
        storeType: "购物中心",
        city: "上海市",
        address: "上海市淞沪路 8 号7F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海百联中环百货",
        storeType: "购物中心",
        city: "上海市",
        address: "上海市普陀区真光路1288 号1F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海博荟广场",
        storeType: "购物中心",
        city: "上海市",
        address: "上海市黄埔区中山南一路 788 号 B1 - 1F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海卜蜂莲花品尊国际店",
        storeType: "大卖场超市",
        city: "上海市",
        address: "上海市上海铜川路 68 号 F",
        time: "08:00 - 22:00",
    },
    {
        store: "上海卜蜂莲花杨高北路店",
        storeType: "大卖场超市",
        city: "上海市",
        address: "上海市上海金高路1256 号 F",
        time: "08:00 - 22:00",
    },
    {
        store: "上海大宁国际商业广场",
        storeType: "百货商场",
        city: "上海市",
        address: "上海市共和新路1898 号 3F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海第一八佰伴百货",
        storeType: "百货商场",
        city: "上海市",
        address: "上海市张杨路 501号7F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海第一百货",
        storeType: "百货商场",
        city: "上海市 ",
        address: "上海市南京东路 830 号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海东方商厦百货",
        storeType: "百货商场",
        city: "上海市 ",
        address: "上海市漕溪北路 8 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海港汇恒隆广场",
        storeType: "购物中心",
        city: "上海市 ",
        address: "上海市徐汇区虹桥路1号 6 楼 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海高岛屋百货",
        storeType: "百货商场",
        city: "上海市 ",
        address: "上海市虹桥路1438 号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海汇金百货",
        storeType: "购物中心",
        city: "上海市 ",
        address: "上海市肇嘉浜路1000 号7F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海九海百盛百货",
        storeType: "百货商场",
        city: "上海市 ",
        address: "上海市淮海中路 918 号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海久光百货",
        storeType: "百货商场",
        city: "上海市 ",
        address: "上海市南京西路1618 号7F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海久光百货大宁店",
        storeType: "百货商场",
        city: "上海市 ",
        address: "上海市静安区共和新路 2188 号 4F",
        time: "08:00 - 20:00",
    },
    {
        store: "上海乐购锦绣店",
        storeType: "大卖场超市",
        city: "上海市 ",
        address: "上海市浦东新区锦绣路 3218 号 F",
        time: "08:00 - 22:00",
    },
    {
        store: "上海乐购七宝店",
        storeType: "大卖场超市",
        city: "上海市 ",
        address: "上海市上海七莘路 3155 号 F",
        time: "08:00 - 22:00",
    },
    {
        store: "上海乐购三门店",
        storeType: "大卖场超市",
        city: "上海市 ",
        address: "上海市上海三门路 501号 F",
        time: "08:00 - 22:00",
    },
    {
        store: "上海乐购真北店",
        storeType: "大卖场超市",
        city: "上海市 ",
        address: "上海市上海铜川路1688 号 F",
        time: "08:00 - 22:00",
    },
    {
        store: "上海人民广场来福士店",
        storeType: "购物中心",
        city: "上海市 ",
        address: "上海市黄浦区西藏中路 268 号 5 楼 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海新世界百货",
        storeType: "百货商场",
        city: "上海市 ",
        address: "上海市南京西路 2 - 68 号7F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海新世界大丸百货",
        storeType: "百货商场",
        city: "上海市 ",
        address: "上海市南京东路 228 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海永安百货",
        storeType: "百货商场",
        city: "上海市 ",
        address: "上海市南京东路 635 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海置地广场百货",
        storeType: "百货商场",
        city: "上海市 ",
        address: "上海市南京东路 49 - 459 号 8F",
        time: "10:00 - 22:00",
    },
    {
        store: "上海中山公园龙之梦",
        storeType: "购物中心",
        city: "上海市 ",
        address: "上海市长宁区长宁路1018 号1F1F",
        time: "10:00 - 22:00",
    },
    {
        store: "成都 IFS 国际金融中心",
        storeType: "购物中心",
        city: "成都市 ",
        address: "四川省成都市成都市锦江区红星路三段1号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "成都仁和春天百货人东店",
        storeType: "百货商场",
        city: "成都市 ",
        address: "四川省成都市青羊区人民东路 59 号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "成都时代奥特莱斯",
        storeType: "购物中心",
        city: "成都市",
        address: "四川省成都市双流县双楠大道中段 633 号 2F",
        time: "10:00 - 22:00",
    },
    {
        store: "成都市高新区仁和新城百货",
        storeType: "购物中心",
        city: "成都市",
        address: "四川省成都市武侯区府城大道西段 505 号 4F",
        time: "10:30 - 22:00",
    },
    {
        store: "成都王府井百货",
        storeType: "百货商场",
        city: "成都市",
        address: "四川省成都市成都市总府路15 号7F",
        time: "10:00 - 22:00",
    },
    {
        store: "成都王府井购物中心",
        storeType: "百货商场",
        city: "成都市",
        address: "四川省成都市武侯区科华中路 24F",
        time: "10:00 - 22:00",
    },
    {
        store: "成都伊势丹百货",
        storeType: "百货商场",
        city: "成都市",
        address: "四川省成都市锦江区红星路三段1号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "成都伊藤洋华堂（高新店 ）",
        storeType: "百货商场",
        city: "成都市",
        address: "四川省成都市成都高新区剑南大道花萌路口（世豪广场） 4F",
        time: "09:00 - 22:30",
    },
    {
        store: "成都伊藤洋华堂（锦华店）",
        storeType: "百货商场",
        city: "成都市 ",
        address: "四川省成都市二环路东五段 29 号万达广场 4F",
        time: "09:00 - 22:30",
    },
    {
        store: "成都伊藤洋华堂（双楠店）",
        storeType: "百货商场",
        city: "成都市 ",
        address: "四川省成都市金牛区二环路西一段逸都路 6 号 4F",
        time: "09:00 - 22:30",
    },
    {
        store: "成都伊藤洋华堂（温江店）",
        storeType: "百货商场",
        city: "成都市 ",
        address: "四川省成都市青羊区顺城大街 252 号顺吉大厦 - 1F",
        time: "09:00 - 22:30",
    },
    {
        store: "成都银泰中心 in99",
        storeType: "购物中心",
        city: "成都市",
        address: "四川省成都市天府大道北段1199 号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "乌鲁木齐美美 2 店",
        storeType: "购物中心",
        city: "乌鲁木齐市",
        address: "新疆维吾尔自治区乌鲁木齐市沙依巴克区友好北路688 号 3F",
        time: "11:00 - 21:30",
    },
    {
        store: "乌鲁木齐美美友好购物中心",
        storeType: "百货商场",
        city: "乌鲁木齐市",
        address:
            "新疆维吾尔自治区乌鲁木齐市沙依巴克区友好北路美美购物中心 4F",
        time: "11:00 - 21:30",
    },
    {
        store: "乌鲁木齐市友好商场",
        storeType: "百货商场",
        city: "乌鲁木齐市",
        address:
            "新疆维吾尔自治区乌鲁木齐市沙依巴克区友好北路518 号友好商场 4F",
        time: "11:00 - 21:30",
    },
    {
        store: "乌鲁木齐天山百货",
        storeType: "百货商场",
        city: "乌鲁木齐市",
        address: "新疆维吾尔自治区乌鲁木齐市和平北路 70 号 5F",
        time: "11:00 - 21:30",
    },
    {
        store: "乌鲁木齐万达广场经开店",
        storeType: "购物中心",
        city: "乌鲁木齐市",
        address: "新疆维吾尔自治区乌鲁木齐市沙依巴克区玄武湖路999 号 2F",
        time: "11:00 - 21:30",
    },
    {
        store: "乌鲁木齐友好百盛购物中心",
        storeType: "百货商场",
        city: "乌鲁木齐市",
        address: "新疆维吾尔自治区乌鲁木齐市沙依巴克区友好南路668 号 - 1F",
        time: "11:00 - 21:30",
    },
    {
        store: "乌鲁木齐友好时尚购物城（昊元店） ",
        storeType: "百货商场",
        city: "乌鲁木齐市",
        address:
            "新疆维吾尔自治区乌鲁木齐市乌新市区西环北路989号友好时尚购物城（昊元上品） - 2F",
        time: "11:00 - 21:30",
    },
    {
        store: "世纪联华义乌南门店",
        storeType: "超市大卖场",
        city: "义乌市 ",
        address: "义乌市城中中路118 号世纪联华南门店",
        time: "10:00 - 20:00",
    },
    {
        store: "昆明 66 恒隆广场",
        storeType: "购物中心",
        city: "昆明市 ",
        address: "云南省昆明市东风东路 21 - 23 号恒隆广场 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "昆明百大家电（顺城店）",
        storeType: "百货商场",
        city: "昆明市 ",
        address: "云南省昆明市昆明三市街新纪元广场 C 座 1F",
        time: "10:00 - 22:00",
    },
    {
        store: "昆明金格百货汇都店",
        storeType: "百货商场",
        city: "昆明市 ",
        address: "云南省昆明市昆明盘龙区白塔路131号汇都国际 - 1F",
        time: "10:00 - 22:00",
    },
    {
        store: "昆明金格百货时光店",
        storeType: "百货商场",
        city: "昆明市 ",
        address: "云南省昆明市盘龙区北京路 985 号金格百货时光店 3F ",
        time: "10:00 - 22:00",
    },
    {
        store: "Ole 杭州嘉里中心店",
        storeType: "大卖场超市",
        city: "杭州市 ",
        address: "浙江省杭州市下城区延安路 353 号 B1 ",
        time: "10:00 - 22:00",
    },
    {
        store: "Ole 杭州万象店",
        storeType: "大卖场超市",
        city: "杭州市 ",
        address: "浙江省杭州市钱江新城富春路 701号 - 1F ",
        time: "08:00 - 22:00",
    },
    {
        store: "Ole 和义大道购物中心店",
        storeType: "大卖场超市",
        city: "宁波市 ",
        address: "浙江省宁波市海曙区和义路 66 号和义大道购物中心 B1 ",
        time: "10:00 - 22:00",
    },
    {
        store: "Ole 温州万象城店",
        storeType: "大卖场超市 ",
        city: "温州市",
        address: "浙江省温州市瓯海区温瑞大道 999 号万象城 B1 ",
        time: "10:00 - 22:00",
    },
    {
        store: "杭州城西银泰 BLT",
        storeType: "大卖场超市 ",
        city: "杭州市 ",
        address: "浙江省杭州市拱墅区萍水街丰潭路 380 号城西银泰 B1 ",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州城西银泰城",
        storeType: "购物中心 ",
        city: "杭州市 ",
        address: "浙江省杭州市拱墅区丰潭路 380 号银泰百货 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "杭州大厦购物中心百货",
        storeType: "购物中心 ",
        city: "杭州市 ",
        address: "浙江省杭州市下城区武林广场 21 号 6F",
        time: "10:00 - 21:40",
    },
    {
        store: "杭州解放路百货商场",
        storeType: "百货商场 ",
        city: "杭州市 ",
        address: "浙江省杭州市上城区解放路 251 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "杭州临安世纪联华",
        storeType: "大卖场超市",
        city: "临安市 ",
        address: "浙江省临安市锦城镇钱王大街 49 号 3F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州世纪联华彩虹城店",
        storeType: "大卖场超市 ",
        city: "杭州市 ",
        address: "浙江省杭州市滨江区滨盛路 4396 号 2F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州世纪联华丰庆店",
        storeType: "大卖场超市 ",
        city: "杭州市 ",
        address: "浙江省杭州市拱墅区丰庆路 710 号 2F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州世纪联华航海店",
        storeType: "大卖场超市",
        city: "杭州市 ",
        address: "浙江省杭州市江干区航海路 878 号 2F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州世纪联华和平店店",
        storeType: "大卖场超市 ",
        city: "杭州市 ",
        address: "浙江省杭州市下城区东新路 155 号和平广场内 3F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州世纪联华建德店",
        storeType: "百货商场 ",
        city: "建德市 ",
        address: "浙江省建德市新安东路 198 号 3F",
        time: "10:00 - 22:00",
    },
    {
        store: "杭州世纪联华江城店",
        storeType: "大卖场超市 ",
        city: "杭州市 ",
        address: "浙江省杭州市上城区江城路 558 号 2F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州世纪联华江南时代",
        storeType: "大卖场超市 ",
        city: "杭州市",
        address:
            "浙江省杭州市余杭区余杭街道荆余路 1 号江南时代购物中心二楼 2F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州世纪联华庆春店",
        storeType: "大卖场超市 ",
        city: "杭州市 ",
        address: "浙江省杭州市下城区庆春路 86 号 5F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州世纪联华外海店",
        storeType: "大卖场超市 ",
        city: "杭州市 ",
        address: "浙江省杭州市江干区庆春东路 58 号 2F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州世纪联华五洲国际",
        storeType: "大卖场超市 ",
        city: "杭州市 ",
        address: "浙江省杭州市余杭塘路 279 号五洲国际广场 0F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州世纪联华西湖文化广场店 ",
        storeType: "大卖场超市 ",
        city: "杭州市 ",
        address: "浙江省杭州市西湖文化广场 B1 - 1F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州世纪联华西湖银泰店 ",
        storeType: "大卖场超市 ",
        city: "杭州市 ",
        address: "浙江省杭州市上城区延安路 530 号 - 1F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州世纪联华下沙店",
        storeType: "百货商场 ",
        city: "杭州市 ",
        address: "浙江省杭州市江干区天城东路 159 号 3F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州世纪联华萧山店卖场",
        storeType: "大卖场超市 ",
        city: "杭州市 ",
        address: "浙江省杭州市南环路 1288 号金帝文源广场 2F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州世纪联华新塘店",
        storeType: "大卖场超市 ",
        city: "杭州市",
        address: "浙江省杭州市江干区新塘路 119 号新城时代广场D 座 2F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州世纪联华星光城店",
        storeType: "超市大卖场 ",
        city: "杭州市 ",
        address: "浙江省杭州市西湖区文三路 500 号",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州世纪联华运河店",
        storeType: "百货商场 ",
        city: "杭州市 ",
        address: "浙江省杭州市拱墅区台州路运河文化广场 - 1F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州万象城购物中心",
        storeType: "购物中心 ",
        city: "杭州市",
        address: "浙江省杭州市江干区四季青镇富春路 701 号钱江新城万象城 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "杭州物美乔司店",
        storeType: "大卖场超市 ",
        city: "杭州市 ",
        address: "浙江省杭州市乔司镇乔莫西路 56 号 0F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州物美文一店卖场",
        storeType: "大卖场超市 ",
        city: "杭州市 ",
        address: "浙江省杭州市西湖区文一路 298 号 2F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州物美西城店卖场",
        storeType: "大卖场超市 ",
        city: "杭州市 ",
        address: "浙江省杭州市西湖区文二西路西城广场 - 1F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州物美下沙店卖场",
        storeType: "大卖场超市 ",
        city: "杭州市",
        address:
            "浙江省杭州市江干区下沙经济技术开发区 4 号大街与 5 号大街交叉口 2F",
        time: "08:00 - 22:00",
    },
    {
        store: "杭州银泰百货",
        storeType: "百货商场 ",
        city: "杭州市 ",
        address: "浙江省杭州市下城区延安路 530 号 7F",
        time: "10:00 - 22:00",
    },
    {
        store: "杭州银泰百货庆春店",
        storeType: "百货商场 ",
        city: "杭州市 ",
        address: "浙江省杭州市江干区景昙路 18 至 26 号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "杭州银泰百货西湖店",
        storeType: "百货商场 ",
        city: "杭州市 ",
        address: "浙江省杭州市上城区延安南路 98 号 - 1F",
        time: "10:00 - 22:00",
    },
    {
        store: "湖州浙北大厦",
        storeType: "百货商场 ",
        city: "湖州市 ",
        address: "浙江省湖州浙江省湖州市吴兴区南街659 - 697号7F",
        time: "10:00 - 22:00",
    },
    {
        store: "嘉兴八佰伴",
        storeType: "百货商场 ",
        city: "嘉兴市 ",
        address: "浙江省嘉兴嘉兴市中山路 1360 号 6F",
        time: "10:00 - 22:00",
    },
    {
        store: "嘉兴戴梦得购物中心百货",
        storeType: "百货商场 ",
        city: "嘉兴市 ",
        address: "浙江省嘉兴市禾兴南路 366 号 5F",
        time: "08:00 - 22:00",
    },
    {
        store: "嘉兴戴梦得购物中心体育店 ",
        storeType: "百货商场 ",
        city: "嘉兴市 ",
        address: "浙江省嘉兴市中环南路体育中心 1F",
        time: "08:00 - 22:00",
    },
    {
        store: "嘉兴海宁华联商厦百货",
        storeType: "百货商场 ",
        city: "海宁市 ",
        address: "浙江省海宁市工人路 58 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "嘉兴南湖天地购物中心",
        storeType: "购物中心 ",
        city: "嘉兴市 ",
        address: "浙江省嘉兴市南湖区南湖路 171 号 B1",
        time: "10:00 - 22:00",
    },
    {
        store: "临海耀达百货",
        storeType: "电器连锁 ",
        city: "台州市 ",
        address: "浙江省台州市临海市回浦路 66 号 2F",
        time: "09:30 - 21:30",
    },
    {
        store: "宁波国购天一店",
        storeType: "百货商场 ",
        city: "宁波市 ",
        address: "浙江省宁波市海曙区中山东路 166 号国际购物中心 2F",
        time: "10:00 - 22:00",
    },
    {
        store: "宁波世纪联华百货",
        storeType: "大卖场超市 ",
        city: "宁波市 ",
        address: "浙江省宁波市江东区中兴路 138 号 3F",
        time: "09:00 - 22:00",
    },
    {
        store: "宁波银泰百货北仑店",
        storeType: "百货商场 ",
        city: "宁波市 ",
        address: "浙江省宁波市宁波北仑泯山路 505 号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "宁波银泰百货海曙店",
        storeType: "百货商场 ",
        city: "宁波市 ",
        address: "浙江省宁波市海曙区中山东路 238 号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "宁波银泰百货江东店",
        storeType: "百货商场 ",
        city: "宁波市 ",
        address: "浙江省宁波市江东区中山东路 1111 号 - 1F",
        time: "10:00 - 22:00",
    },
    {
        store: "宁波银泰百货天一店",
        storeType: "百货商场 ",
        city: "宁波市 ",
        address: "浙江省宁波市海曙区中山东路 188 号 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "宁波银泰百货万达店",
        storeType: "百货商场 ",
        city: "宁波市 ",
        address: "浙江省宁波市鄞州区？四明中路999号万达商业广场4F",
        time: "10:00 - 22:00",
    },
    {
        store: "绍兴国商大厦",
        storeType: "百货商场 ",
        city: "绍兴市 ",
        address: "浙江省绍兴浙江省绍兴市解放北路 449 号 6F",
        time: "09:15 - 21:30",
    },
    {
        store: "绍兴上虞大通",
        storeType: "百货商场 ",
        city: "上虞市 ",
        address: "浙江省上虞市人民路 389 号 3F",
        time: "09:00 - 21:30",
    },
    {
        store: "世纪联华江晖店",
        storeType: "百货商场 ",
        city: "杭州市 ",
        address: "浙江省杭州市滨江区江晖路 1402 号 - 1F",
        time: "08:00 - 22:00",
    },
    {
        store: "世纪联华椒江店",
        storeType: "大卖场超市 ",
        city: "台州市 ",
        address: "浙江省台州市椒江区市府大道 555 号 3F",
        time: "09:00 - 22:00",
    },
    {
        store: "温州世纪联华大世界店",
        storeType: "大卖场超市 ",
        city: "温州市 ",
        address: "浙江省温州市鹿城区西城路 49 号 2F",
        time: "09:00 - 22:00",
    },
    {
        store: "温州银泰百货世贸店",
        storeType: "百货商场 ",
        city: "温州市 ",
        address: "浙江省温州市温州鹿城区解放南路 9 号地块 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "物美笕桥店",
        storeType: "大卖场超市 ",
        city: "杭州市 ",
        address: "浙江省杭州市江干区机场路 371 号 2F",
        time: "08:00 - 22:00",
    },
    {
        store: "下沙金沙湖天街生活馆",
        storeType: "购物中心 ",
        city: "杭州市 ",
        address: "浙江省杭州市下沙金额沙湖天街 4F018 感觉生活馆 4F ",
        time: "10:00 - 22:00",
    },
    {
        store: "萧山银隆百货",
        storeType: "百货商场 ",
        city: "杭州市 ",
        address: "浙江省杭州市萧山市心中路 288 号 4F ",
        time: "10:00 - 22:00",
    },
    {
        store: "义乌世纪联华望道路店",
        storeType: "大卖场超市 ",
        city: "义乌市 ",
        address: "浙江省义乌市望道路 168 号 3F ",
        time: "08:00 - 22:00",
    },
    {
        store: "永旺梦乐城杭州良渚新城",
        storeType: "大卖场超市 ",
        city: "杭州市 ",
        address: "浙江省杭州市市余杭区古墩路 1888 号 2F ",
        time: "08:00 - 22:00",
    },
    {
        store: "舟山定海凯虹广场",
        storeType: "百货商场 ",
        city: "宁波市 ",
        address: "浙江省宁波市浙江省舟山市定海区东瀛路 199 号 5F ",
        time: "10:00 - 22:00",
    },
    {
        store: "舟山凯虹广场",
        storeType: "百货商场",
        city: " 舟山市 ",
        address: "浙江省舟山市普陀区兴普大道 288 号凯虹广场 5 楼 5F ",
        time: "10:00 - 22:00",
    },
    {
        store: "重庆国金中心绿地超市",
        storeType: "大卖场超市 ",
        city: "重庆市 ",
        address: "重庆市江北区江北城北大街 38 号国金中心 T6 栋 1F ",
        time: "10:00 - 22:00",
    },
    {
        store: "重庆京东超级体验店",
        storeType: "电器连锁 ",
        city: "重庆市 ",
        address: "重庆市江北区金渝大道 149 号京东电器超级体验店 1F ",
        time: "10:00 - 22:00",
    },
    {
        store: "重庆龙湖金沙天街",
        storeType: "购物中心 ",
        city: "重庆市 ",
        address: "重庆市沙坪坝区金沙天街 A 馆 - 4F - C024F ",
        time: "10:00 - 22:00",
    },
    {
        store: "重庆世纪新都",
        storeType: "百货商场 ",
        city: "重庆市 ",
        address: "重庆市江北区建新北路 37 号 4F ",
        time: "10:00 - 22:00",
    },
    {
        store: "重庆万象城博朗旗舰店",
        storeType: "购物中心 ",
        city: "重庆市 ",
        address: "重庆市九龙坡区谢家湾正街 53 号华润广场中区L516 商铺 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "重庆新世纪百货（解放碑商都）",
        storeType: "百货商场 ",
        city: "重庆市 ",
        address: "重庆市渝中区青年路 18 号解放碑步行街 6F ",
        time: "09:30 - 21:30 ",
    },
    {
        store: "重庆星光 68 广场 ",
        storeType: "购物中心 ",
        city: "重庆市 ",
        address: "重庆市江北区观音桥洋河 1 路 68 号 - 1F ",
        time: "10:00 - 22:00",
    },
    {
        store: "重庆远东百货有限公司",
        storeType: "百货商场 ",
        city: "重庆市 ",
        address: "重庆市江北区北城天街 10 号 - 1F ",
        time: "10:00 - 22:00",
    },
    {
        store: "南区",
        storeType: " ",
        city: " ",
        address: "",
        time: "",
    },
    {
        store: "福州 FeelJust 汇聚进口高端家电生活馆",
        storeType: "购物中心 ",
        city: "厦门市",
        address: "福建省厦门市思明区湖滨东路 97 号华润万象城L413 店铺 4F",
        time: "10:00 - 22:00",
    },
    {
        store: "厦门天虹商场大西洋店",
        storeType: "百货商场 ",
        city: "厦门市",
        address: "福建省厦门市厦门市思明区湖滨西路 9 号大西洋海景城 5F",
        time: "10:00 - 22:00 ",
    },
    {
        store: "兰州国芳百货购物广场",
        storeType: "百货商场",
        city: " 兰州市 ",
        address: "甘肃省兰州市城关区东方红广场国芳百货 7F ",
        time: "09:30 - 21:30",
    },
    {
        store: "兰州新世界百货",
        storeType: "百货商场 ",
        city: "兰州市 ",
        address: "甘肃省兰州市兰州市城关区张掖路 89 号 4F ",
        time: "09:30 - 21:30",
    },
    {
        store: "兰州亚欧百货",
        storeType: "百货商场 ",
        city: "兰州市 ",
        address: "甘肃省兰州市城关区中山路 120 号 6F ",
        time: "09:30 - 21:30",
    },
    {
        store: "Ole 广州天汇广场店",
        storeType: "大卖场超市 ",
        city: "广州市 ",
        address: "广东省广州市天河区兴民路 222 号天汇广场 B2 ",
        time: "10:00 - 22:00",
    },
    {
        store: "Ole 深圳华润益田店",
        storeType: "大卖场超市 ",
        city: "深圳市 ",
        address: "广东省深圳市南山区深南大道 9028 号 1F ",
        time: "10:00 - 22:00",
    },
    {
        store: "Ole 深圳深业上城店",
        storeType: "大卖场超市 ",
        city: "深圳市 ",
        address: "广东省深圳市广东省深圳市福田区皇岗路 5001 号深业上城 B1",
        time: "10:00 - 22:00 ",
    },
    {
        store: "Ole 深圳万象城店",
        storeType: "大卖场超市 ",
        city: "深圳市 ",
        address: "广东省深圳市深圳市罗湖区宝安南路华润万象城 B1 ",
        time: "10:00 - 22:00",
    },
    {
        store: "Ole 深圳万象天地店",
        storeType: "大卖场超市 ",
        city: "深圳市 ",
        address: "广东省深圳市南山区深南大道9668号华润万象天地B1 ",
        time: "10:00 - 22:00",
    },
    {
        store: "佛山顺联国际购物中心",
        storeType: "购物中心 ",
        city: "佛山市 ",
        address: "广东省佛山市禅城区季华五路 33 号 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "广州广百八楼百货",
        storeType: "百货商场 ",
        city: "广州市 ",
        address: "广东省广州市西湖路 12 号广州百货商店 8 楼 8F",
        time: "10:00 - 22:00",
    },
    {
        store: "广州太古汇 Ole 店",
        storeType: "大卖场超市 ",
        city: "广州市",
        address:
            "广东省广州市天河路一街 383 号太古汇商场裙楼地铁层 M21号商铺",
        time: "10:00 - 22:00",
    },
    {
        store: "广州天汇广场购物中心",
        storeType: "购物中心 ",
        city: "广州市 ",
        address: "广东省广州市天河区兴民路 222 号天汇广场 3F",
        time: "10:00 - 22:00",
    },
    {
        store: "广州王府井百货",
        storeType: "百货商场 ",
        city: "广州市 ",
        address: "广东省广州市农林下路 40 号王府井百货 7 楼 7F",
        time: "10:00 - 22:00",
    },
    {
        store: "广州友谊国金店百货",
        storeType: "百货商场 ",
        city: "广州市 ",
        address: "广东省广州市天河区珠江西路 5 号友谊商店 MF",
        time: "10:00 - 22:00",
    },
    {
        store: "广州友谊商店百货",
        storeType: "百货商场 ",
        city: "广州市",
        address: "广东省广州市环市东路 369 号友谊商店世贸新馆5 楼 5F",
        time: "10:00 - 22:00",
    },
    {
        store: "广州中怡广百百货",
        storeType: "百货商场 ",
        city: "广州市 ",
        address: "广东省广州市天河路 200 号中怡广场广百 7 楼 7F",
        time: "10:00 - 22:00",
    },
    {
        store: "深圳华强茂业百货",
        storeType: "百货商场 ",
        city: "深圳市",
        address: "广东省深圳市广东省深圳市福田区华强北路 20号8F",
        time: "10:00 - 22:00",
    },
    {
        store: "深圳天虹商场后海店",
        storeType: "百货商场 ",
        city: "深圳市",
        address:
            "深圳市南山区文心五路 33 号保利文化广场 C 区后海天虹 F1 博朗专柜",
        time: "周五至周六\r\n10:00 - 22:30\r\n周一至周四，周日\r\n10:00 - 22:00",
    },
    {
        store: "深圳天虹商场沙河店",
        storeType: "百货商场 ",
        city: "深圳市",
        address:
            "广东省深圳市南山区白石路 168 号京基百纳广场 - 天虹商场沙河店 B1",
        time: "10:00 - 22:00",
    },
    {
        store: "广西柳州市柳州商场",
        storeType: "百货商场 ",
        city: "柳州市",
        address: "广西柳州市城中区龙城路二号工贸大厦商场 - 1楼 - 1F",
        time: "周五至周六\r\n10:00 - 22:30\r\n周一至周四，周日\r\n10:00 - 22:00",
    },
    {
        store: "Ole 南宁万象城店",
        storeType: "大卖场超市 ",
        city: "南宁市 ",
        address: "广西省南宁市民族大道 136 号万象 B1",
        time: "10:00 - 22:00",
    },
    {
        store: "南宁梦之岛水晶城百货",
        storeType: "百货商场 ",
        city: "南宁市 ",
        address: "广西省南宁市青秀区金湖路61号梦之岛水晶城百货 - 1F",
        time: "周一至周五\r\n10:00 - 22:30 \r\n周六至周日\r\n09:30 - 22:30",
    },
    {
        store: "全国顺电实体门店有售",
        storeType: " ",
        city: " ",
        address: "",
        time: "",
    },
    {
        store: "全国山姆实体门店有售",
        storeType: " ",
        city: " ",
        address: "",
        time: "",
    },
    {
        store: "全国开市客实体门店有售",
        storeType: " ",
        city: " ",
        address: "",
        time: "",
    },
    {
        store: "* 上述门店地址确定于 2021 年 12 月，会有不定期更新。",
        storeType: " ",
        city: " ",
        address: "",
        time: "",
    },
];
class StoreAddress extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Sheet1:Sheet1
        };
    }

    componentDidMount() {
        document.title = '博朗,博朗剃须刀-博朗最新全国销售门店地址'

    }

    render() {
        let {Sheet1} = this.state
        return (
            <div className="homePages">
                <h2>
                    <img className="logo" src={logoImg} alt=""/>
                        最新全国销售门店地址
                </h2>
                <div className="pure-table">
                    <div className="titTable">
                        <div className='tab1'>门店</div>
                        <div className='tab2'>门店类型</div>
                        <div className='tab3'>城市</div>
                        <div className='tab4'>地址</div>
                        <div className='tab5'>营业时间</div>
                    </div>
                    {
                        Sheet1.map((item,index) => {
                            return (
                                <div className="titTables" key={index}>
                                    <div className='tab1'>{item.store}</div>
                                    <div className='tab2'>{item.storeType}</div>
                                    <div className='tab3'>{item.city}</div>
                                    <div className='tab4'>{item.address}</div>
                                    <div className='tab5'>{item.time}</div>
                                </div>
                            )
                        })
                        }
                </div>
            </div>
        )
    }
}

export default StoreAddress;
