import React, {Component} from "react";
import cmsRequest from "../../api/cmsRequest";
import Footer from "../../components/Footer"

class Gillettevector2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mainImgItem: "https://lowcode-0gtpsuvqc0b7d6b3-1309022365.tcloudbaseapp.com/resources/2023-04/lowcode-1120946",
            year: 2024,
            ICPCodeItem: {},
            recordCodeItem: {}
        };
    }

    componentDidMount() {
        // document.title = '男士剃须刀 | 吉列威锋'
        document.title = '吉列剃刀系列2官网'
        cmsRequest.request({ mid: '1fd902bfa6' })
            .then(result => {
                let cmsData = result.data
                let mainImgItem = cmsData.find(item => item.id === '4342323080').content[0]
                let ICPCodeItem = cmsData.find(item => item.id === '3c629eda66')
                let recordCodeItem = cmsData.find(item => item.id === 'fc3f6fb147')
                this.setState({
                    mainImgItem,
                    ICPCodeItem,
                    recordCodeItem
                })
            })
            .catch(err => {
                console.log('data catch', err)
            })

        cmsRequest.request({ mid: '494d6de189' })
            .then(result => {
                let cmsData = result.data
                let year = cmsData.find(item => item.id === '63a92cfade').content
                this.setState({
                    year
                })
            })
            .catch(err => {
                console.log('year catch', err)
            })
    }

    render() {
        let { mainImgItem, year, ICPCodeItem, recordCodeItem } = this.state
        return (
            <div className="homePage">
                {
                    mainImgItem.isShow && (
                        <img className="mainImg" src={mainImgItem.imageUrl} alt={mainImgItem.parameters[0].value}></img>
                    )
                }
                {
                    ICPCodeItem.isShow && recordCodeItem.isShow && (
                        <Footer ICPCodeItem={ICPCodeItem} recordCodeItem={recordCodeItem} year={year}></Footer>
                    )
                }
            </div>
        )
    }
}

export default Gillettevector2;
