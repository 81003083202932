import React, { Component } from "react";
import './privacy.css'

class Privacy extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        document.title = ''
    }

    render() {
        let {} = this.state
        return (
            <div className="privacy">
                <iframe src='privacy.html' width={'100%'} height={'100%'} style={{border: 0, height: '100vh'}}/>
            </div>
        )
    }
}

export default Privacy;
