import React, {Component} from "react";
import cmsRequest from "../../api/cmsRequest";
import Footer from "../../components/Footer"

class HeadShoulders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mainImgItem: {},
            year: 2024,
            ICPCodeItem: {},
            recordCodeItem: {}
        };
    }

    componentDidMount() {
        document.title = '宝洁海飞丝官网'
        cmsRequest.request({ mid: '1bc12a44c5' })
            .then(result => {
                let cmsData = result.data
                let mainImgItem = cmsData.find(item => item.id === '602dff16a7').content[0]
                let ICPCodeItem = cmsData.find(item => item.id === '955ed08316')
                let recordCodeItem = cmsData.find(item => item.id === '8a9ac4fcde')
                this.setState({
                    mainImgItem,
                    ICPCodeItem,
                    recordCodeItem
                })
            })
            .catch(err => {
                console.log('data catch', err)
            })
        
        cmsRequest.request({ mid: '494d6de189' })
            .then(result => {
                let cmsData = result.data
                let year = cmsData.find(item => item.id === '63a92cfade').content
                this.setState({
                    year
                })
            })
            .catch(err => {
                console.log('year catch', err)
            })
    }

    render() {
        let { mainImgItem, ICPCodeItem, recordCodeItem, year } = this.state
        return (
            <div className="homePage">
                {
                    mainImgItem.isShow && (
                        <img className="mainImg" src={mainImgItem.imageUrl} alt={mainImgItem.parameters[0].value}></img>
                    )
                }
                {
                    ICPCodeItem.isShow && recordCodeItem.isShow && (
                        <Footer ICPCodeItem={ICPCodeItem} recordCodeItem={recordCodeItem} year={year}></Footer>
                    )
                }
            </div>
        )
    }
}

export default HeadShoulders;