import React, {Component} from "react";
import cmsRequest from "../../api/cmsRequest";
import Footer from "../../components/Footer"

class Gillettevector3 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mainImgItem: {},
            year: 2024,
            ICPCodeItem: {},
            recordCodeItem: {}
        };
    }

    componentDidMount() {
        // document.title = '男士剃须刀 | 吉列中国'
        document.title = '吉列剃刀系列3官网'
        cmsRequest.request({ mid: '527aaccd78' })
            .then(result => {
                let cmsData = result.data
                let mainImgItem = cmsData.find(item => item.id === 'afa87b1cf7').content[0]
                let ICPCodeItem = cmsData.find(item => item.id === '6f209ad7f0')
                let recordCodeItem = cmsData.find(item => item.id === '5ab9cef5d9')
                this.setState({
                    mainImgItem,
                    ICPCodeItem,
                    recordCodeItem
                })
            })
            .catch(err => {
                console.log('data catch', err)
            })

        cmsRequest.request({ mid: '494d6de189' })
            .then(result => {
                let cmsData = result.data
                let year = cmsData.find(item => item.id === '63a92cfade').content
                this.setState({
                    year
                })
            })
            .catch(err => {
                console.log('year catch', err)
            })
    }

    render() {
        let { mainImgItem, ICPCodeItem, recordCodeItem, year } = this.state
        return (
            <div className="homePage">
                {
                    mainImgItem.isShow && (
                        <img className="mainImg" src={mainImgItem.imageUrl} alt={mainImgItem.parameters[0].value}></img>
                    )
                }
                {
                    ICPCodeItem.isShow && recordCodeItem.isShow && (
                        <Footer ICPCodeItem={ICPCodeItem} recordCodeItem={recordCodeItem} year={year}></Footer>
                    )
                }
            </div>
        )
    }
}

export default Gillettevector3;
