import './App.css';
import Root from './router/Root';
import { ConfigProvider } from 'antd';
import moment from 'moment';
moment.locale('zh-cn');

function App() {
    return (
        <ConfigProvider>
            <Root/>
        </ConfigProvider>
    );
}

export default App;
